import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ProcedureStampType } from '@models/imported/SagaBase';
import { CStampProcedure } from '@models/imported/SagaSchema/CStampProcedure';
import { CallCard } from '@models/imported/SagaSchema/CallCard';
import { ModalService } from '@services/modal/modal.service';
import { AppSettingProviderService } from '@services/settings';
import { AppSettings } from '@services/settings/schema/enums/app-settings';
import { MyUnitService } from '@services/unit-activity/my-unit/my-unit.service';
import { KeypadModalComponent } from '../../components/keypad-modal/keypad-modal.component';
import { EngagedPeopleProxyService } from '../engaged-people-proxy/engaged-people-proxy.service';

@Injectable({
  providedIn: 'root'
})
export class ResourceCountService {
  constructor(
    private engagedPeopleProxyService: EngagedPeopleProxyService,
    private myUnitService: MyUnitService,
    private modalService: ModalService,
    private modalController: ModalController,
    private appSettingProvider: AppSettingProviderService
  ) {}

  getEngagedPeople(callcard: CallCard): number {
    const unitId: string = this.myUnitService.mine.ObjGuid;
    const engagementProcedureStamps: CStampProcedure[] = this.getProcedureStamps(callcard).filter(
      stamp => stamp.ElementId == unitId && stamp.StampType === ProcedureStampType.IsMobileEngagement
    );

    return engagementProcedureStamps.length
      ? parseInt(engagementProcedureStamps.reduce((result, current) => (result.CreatedUTC > current.CreatedUTC ? result : current)).Text)
      : 0;
  }

  private getProcedureStamps(callcard: CallCard): CStampProcedure[] {
    return callcard.component<CStampProcedure>(CStampProcedure.$t).length ? callcard.component<CStampProcedure>(CStampProcedure.$t) : [];
  }

  getResourceCount(callcard: CallCard): number {
    let engagedPeople: number = 0;

    this.getProcedureStamps(callcard).forEach(stamp => {
      if (stamp.StampType === ProcedureStampType.IsMobileDeengagement) {
        engagedPeople -= parseInt(stamp.Text);
      } else if (stamp.StampType === ProcedureStampType.IsMobileEngagement) {
        engagedPeople += parseInt(stamp.Text);
      }
    });

    return engagedPeople;
  }

  updateEngagedPeople(): Promise<boolean> {
    return this.selectEngagedPeople()
      .then(value =>
        this.modalService.presentLoading().then(() =>
          this.engagedPeopleProxyService
            .updateEngagedPeople(value)
            .toPromise()
            .finally(() => this.modalService.dismissLoading())
        )
      )
      .catch(() => Promise.resolve(false));
  }

  selectEngagedPeople(): Promise<number> {
    let isLargeFontEnabled: boolean = false;
    this.appSettingProvider.getBooleanSetting(AppSettings.LargeFontEnabled, false, true).subscribe(appSetting => {
      isLargeFontEnabled = appSetting.current;
    });

    return this.modalController
      .create({
        component: KeypadModalComponent,
        componentProps: {
          maxLength: 2,
          validator: this.engagedPeopleValid
        },
        cssClass: isLargeFontEnabled ? 'fullscreen-modal' : undefined
      })
      .then(modal =>
        modal.present().then(() =>
          modal.onDidDismiss().then(result => {
            const confirmed: boolean = !!result.data;

            if (confirmed) {
              return Promise.resolve(result.data.result);
            }
            return Promise.reject();
          })
        )
      );
  }

  private engagedPeopleValid(value: string): boolean {
    return value && value != '0' && value != '00';
  }
}
