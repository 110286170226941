import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { RefreshTokenStorageService } from '@services/refresh-token-storage/refresh-token-storage.service';
import { UnitActivity } from '@models/imported/SagaSchema/UnitActivity';
import { catchError } from 'rxjs/operators';
import { CommunicationEquipment_abstract } from '@models/imported/abstract/SagaSchema/CommunicationEquipment_abstract';
import { ListNameValue } from '@models/imported/SagaSchema/ListObjects/ListNameValue';
import { ImeiAlias } from '@addins/pn/services/unit-activity-selector/unit-activity-selector.service';
import { IUnitSetup } from '@techwan/ionic-core';

export interface IUpdateRequestArgs {
  activityUid: string;
  addedVehicles: [];
  addedEquipments: CommunicationEquipment_abstract[];
  removedVehicles: [];
  removedEquipments: [];
}

@Injectable()
export class UnitActivityProxyService {
  constructor(private readonly http: HttpClient, private readonly token: RefreshTokenStorageService) {}

  getAvailableRfgiImei(): Observable<ListNameValue<ImeiAlias>[]> {
    return this.http.get<ListNameValue<ImeiAlias>[]>('api/RfgiImei/GetAvailableRfgiImei');
  }

  associateWithIdentifier(data: any): Observable<boolean> {
    const user = this.token.read().login.SagaUserAuth;
    return this.http.post<boolean>(
      'api/UnitActivity/AssociateUnitActivityWithIdentifier',
      Object.assign({}, data, {
        owner: user.SiteId,
        ownerName: user.OwnerName,
        userId: user.UserID,
        userName: user.UserName
      })
    );
  }

  updateUnitActivity(updateRequest: IUpdateRequestArgs): Observable<UnitActivity> {
    return this.http
      .post('api/UnitActivity/UpdateUnit', updateRequest)
      .pipe(catchError((err, caught: Observable<UnitActivity>) => this.handleError(err, caught)));
  }

  getUnitActivities(filterStatus: number): Observable<IUnitSetup[]> {
    const args = {
      siteId: this.token.read().login.SagaUserAuth.Owner,
      filterStatus
    };
    return this.http.post<IUnitSetup[]>('api/UnitActivity/GetUnitActivities', args);
  }

  getAllUnitActivitiesCallSign() {
    return this.http.get<any[]>('api/UnitActivity/GetAllUnitActivitiesCallSign');
  }

  private handleError(err: any, caught: Observable<UnitActivity>): Observable<UnitActivity> {
    return of(null);
  }
}
