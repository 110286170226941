import { ILocalizeRequest, LocalizePositionService, ProjectionConverterService } from '@addins/core/map';
import { StartItineraryService } from '@addins/external-itinerary';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Coordinate } from '@models/coordinate';
import { CLocation } from '@models/imported/SagaSchema/CLocation';
import { MediaMessage } from '@models/imported/SagaSchema/MediaMessage';
import { ImagesSettingsService } from '@services/settings';
import { Subject, Subscription } from 'rxjs';

@Component({
  selector: 'app-media-message-deployment',
  templateUrl: './media-message-deployment.component.html',
  styleUrls: ['./media-message-deployment.component.scss']
})
export class MediaMessageDeploymentComponent implements OnInit, OnDestroy {
  @Input()
  readonly mediaMessage: MediaMessage;

  get navigationImg(): string {
    return this._imagesService.get('SygicDeploymentPoint');
  }

  get localizeDeploymentImg(): string {
    return this._imagesService.get('LocalizeDeployment');
  }

  private _localizeDeploymentEvent: Subject<ILocalizeRequest>;
  private _localizeDeploymentSubscription: Subscription = null;

  constructor(
    private _imagesService: ImagesSettingsService,
    private _localizePositionService: LocalizePositionService,
    private _projectionConverter: ProjectionConverterService,
    private _startItineraryService: StartItineraryService
  ) {}

  ngOnInit() {
    this._localizeDeploymentEvent = new Subject<ILocalizeRequest>();
    this._localizeDeploymentSubscription = this._localizePositionService.listenForLocation(this._localizeDeploymentEvent);
  }

  ngOnDestroy() {
    this._localizeDeploymentSubscription.unsubscribe();
    this._localizeDeploymentSubscription = null;
  }

  openNavigation() {
    this._startItineraryService.navigateTo(this.getCoordinate());
  }

  private getCoordinate(): Coordinate {
    return {
      x: this.mediaMessage.CoordX,
      y: this.mediaMessage.CoordY,
      epsg: this._projectionConverter.getProjection()
    };
  }

  onLocalize() {
    const location = new CLocation();
    location.coordinate = this.getCoordinate();
    this._localizeDeploymentEvent.next({
      positions: [location],
      target: null
    });
  }
}
