import { CurrentCardService, MyCardService } from '@addins/core/core';
import { MenuState } from '@addins/core/menus';
import { Component, OnDestroy, OnInit, Type } from '@angular/core';
import { CallCard } from '@models/imported/SagaSchema/CallCard';
import { CacheService, CacheState } from '@services/cache/cache.service';
import { AddinView } from '@techwan/ionic-core';
import { Subscription } from 'rxjs';
import { LAYOUT_VIEW_NAME } from '../../schema/constants';
import { CardsProviderService } from '../../services/cards-provider/cards-provider.service';
import { CallcardOpenerService } from '../../services/executables/callcard-opener/callcard-opener.service';
import { MenuAccessService } from '../../services/executables/menu-access/menu-access.service';

@AddinView({
  name: LAYOUT_VIEW_NAME
})
@Component({
  selector: 'app-layout-view',
  templateUrl: './layout-view.component.html',
  styleUrls: ['./layout-view.component.scss'],
  providers: [MenuAccessService]
})
export class LayoutViewComponent implements OnInit, OnDestroy {
  private readonly _sub: Subscription[] = [];

  get myCardId(): string {
    return this._myCard.myCardId;
  }

  get myCard(): CallCard {
    return this._current.current || this._myCard.myCard;
  }

  get cards(): Type<any>[] {
    return this._customItems.cards;
  }

  constructor(
    private readonly _myCard: MyCardService,
    private readonly cache: CacheService,
    private readonly menu: MenuAccessService,
    private readonly _current: CurrentCardService,
    private readonly _customItems: CardsProviderService,
    private readonly _opener: CallcardOpenerService
  ) {}

  ngOnInit() {
    // Tells the card opener not to operate on a card change.
    this._opener.destroy();

    this._sub.splice(
      0,
      0,
      this.cache.state.subscribe(e => this.onCacheEvent(e))
    );
  }

  ngOnDestroy() {
    while (this._sub.length) {
      this._sub.pop().unsubscribe();
    }
  }

  show(): boolean {
    return this._current.current ? true : this.menu.entry.isActive ? (this.myCard && this.myCard instanceof CallCard) || false : false;
  }

  private $changed(selected?: CallCard): void {
    if (this._myCard.myCard !== null) {
      this.menu.entry.setState(this._myCard.myCard === selected ? MenuState.active : MenuState.inactive);
    }
  }

  private onCacheEvent(cacheState: CacheState) {
    if (cacheState === CacheState.ready && this._sub.length < 2) {
      this._sub.push(this._current.$change.subscribe(callcard => this.$changed(callcard)));
    } else if (cacheState === CacheState.idle && this._sub.length > 1) {
      this._sub.pop().unsubscribe();
    }
  }
}
