import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { LayoutService } from '@services/layout/layout.service';
import { MenuEntry } from '@services/menu/entry.schema';
import { SideMenuService } from '@services/menu/side-menu.service';
import { ProfileService } from '@services/profile/profile.service';
import { ViewConfigurationService } from '@services/view-configuration/view-configuration.service';
import { AddinExecute, AddinService, Execute } from '@techwan/ionic-core';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

/**
 * @description
 * Add a menu entry for open the map
 */
@AddinExecute({
  name: 'Menu',
  description: 'Add a menu entry for open the map'
})
@Injectable()
export class MapAccessMenuService extends Execute {
  private menuEntry: MenuEntry;

  private profileReadySub: Subscription = null;

  constructor(
    private menuService: SideMenuService,
    private platform: Platform,
    private addins: AddinService,
    private config: ViewConfigurationService,
    private profile: ProfileService,
    private layout: LayoutService
  ) {
    super();
  }

  init() {}

  execute(): void {
    if (this.profile.isReady()) {
      this.createMenu();
    } else {
      this.profileReadySub = this.profile.ready.pipe(filter(isReady => isReady)).subscribe(() => this.createMenu());
    }
  }

  destroy(): void {
    this.clear();
    if (this.menuEntry) {
      this.menuService.removeMenuEntry(this.menuEntry);
      this.menuEntry = null;
    }
  }

  private createMenu(): void {
    if (!this.check()) {
      return;
    }

    this.clear();
    this.menuEntry = new MenuEntry('map', 'map', this.addins.resolveAddinView(this.config.getMapName()).element, 600);
    this.menuService.addMenuEntry(this.menuEntry);
  }

  private clear(): void {
    if (this.profileReadySub) {
      this.profileReadySub.unsubscribe();
      this.profileReadySub = null;
    }
  }

  private check(): boolean {
    return !this.platform.is('tablet') || 'map' in this.layout.layout;
  }
}
