import { JournalToolsProviderService } from '@addins/singlecallcard-journal';
import { Injectable } from '@angular/core';
import { Task } from '@models/imported/SagaSchema/Task';
import { MyUnitService } from '@services/unit-activity/my-unit/my-unit.service';
import { UnitActivityMDP } from '../../models/UnitActivityMDP';
import { AddFreeTextTool } from '../../schema/add-free-text-tool';
import { AddFreeTextService } from '../add-freetext/add-freetext.service';

@Injectable({
  providedIn: 'root'
})
export class JournalToolsControllerService {
  private addFreeTextTool: AddFreeTextTool;

  constructor(
    private journalTools: JournalToolsProviderService,
    private addFreeTextService: AddFreeTextService,
    private myUnit: MyUnitService
  ) {}

  setup() {
    this.addFreeTextTool = new AddFreeTextTool(
      task => this.addFreeTextService.addFreeText(task.ObjGuid),
      task => this.isJournalToolEnabledFor(task)
    );
    this.journalTools.add(this.addFreeTextTool);
  }

  private isJournalToolEnabledFor(task: Task): boolean {
    const taskId: string = task.ObjGuid;

    if (task instanceof Task) {
      return this.myUnit.mine && (this.myUnit.mine as UnitActivityMDP).TaskID == taskId;
    } else {
      // Callcard
      return this.myUnit.myCardId == taskId;
    }
  }
}
