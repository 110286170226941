import { Injectable } from '@angular/core';
import { ProfileReaderService } from '@services/profile-reader/profile-reader.service';
import { AddinExecute, Execute } from '@techwan/ionic-core';
import { CallCardsCustomFiltersService } from '../../callcards-custom-filters/callcards-custom-filters.service';
import { PriorityFilterService } from '../../priority-filter/priority-filter.service';

@AddinExecute({
  name: 'CustomFiltersSetup',
  description: 'setup callcards list custom filters'
})
@Injectable()
export class CustomFiltersSetupService extends Execute {
  constructor(
    private profileReader: ProfileReaderService,
    private callcardsCustomFilters: CallCardsCustomFiltersService,
    private priorityFilter: PriorityFilterService
  ) {
    super();
  }

  execute() {
    const filters: string[] = this.profileReader.getComponentConfig('listcallcards', 'filters', []);
    if (filters.indexOf('Priority') >= 0) {
      this.priorityFilter.setup();
      this.callcardsCustomFilters.addFilter(this.priorityFilter);
    }
  }
}
