import { FileBridgeService, FileSystemService } from '@addins/core/file';
import { Injectable } from '@angular/core';
import { DirectoryEntry, FileEntry } from '@ionic-native/file/ngx';
import { Platform } from '@ionic/angular';
import { FileDescriptor } from '@models/imported/SagaSchema/FileDescriptor';
import { CacheService, CacheState } from '@services/cache/cache.service';
import { UploadEvent, UploadEventType, UploadManagerService } from '@techwan/file-upload';
import { filter } from 'rxjs/operators';
import { ICustomFile } from '../../schema/interfaces/ICustomFile';
import { ISagaAttachment } from '../../schema/interfaces/ISagaAttachment';
import { SagaFileEntryUploadItem } from '../../schema/saga-file-entry-upload-item';
import { SagaFileUploadItem } from '../../schema/saga-file-upload-item';

@Injectable()
export class UploadRepositoryService {
  private readonly sagaAttachments: ISagaAttachment[] = [];

  private readonly UPLOADS_STORAGE_NAME = '.uploads.json';
  private rootDirectory: string = null;

  constructor(
    private fileSystemService: FileSystemService,
    private fileBridge: FileBridgeService,
    private uploadManager: UploadManagerService,
    private platform: Platform,
    private cacheService: CacheService
  ) {}

  setup() {
    if (this.platform.is('cordova')) {
      this.rootDirectory = this.fileSystemService.getDocumentsDirectory() || this.fileSystemService.getDataDirectory();

      this.uploadManager.$event.subscribe(uploadEvent => this.onUploadEvent(uploadEvent));

      this.cacheService.state.pipe(filter(cacheState => cacheState === CacheState.ready)).subscribe(() => {
        this.loadUploads();
      });
    }
  }

  private loadUploads(): Promise<any> {
    return this.fileBridge
      .getFileFrom(this.rootDirectory, this.UPLOADS_STORAGE_NAME, true)
      .then(file => this.fileBridge.readFileAsString(file))
      .then(json => {
        const sagaAttachments = json ? (JSON.parse(json) as ISagaAttachment[]) : [];

        sagaAttachments.forEach(sagaAttachment => {
          this.fileBridge.getEntry(sagaAttachment.localURL).then((fileEntry: FileEntry) =>
            this.fileBridge.getFile(fileEntry).then(file => {
              this.sagaAttachments.push(sagaAttachment);

              const uploadItem: SagaFileEntryUploadItem = new SagaFileEntryUploadItem(
                file as any,
                this.createFileDescriptor(sagaAttachment),
                sagaAttachment.callcard
              );

              this.uploadManager.queue(uploadItem, false);
            })
          );
        });
      });
  }

  private createFileDescriptor(sagaAttachment: ISagaAttachment): FileDescriptor {
    const fileDescriptor: FileDescriptor = new FileDescriptor();
    fileDescriptor.ObjGuid = sagaAttachment.fileUid;
    fileDescriptor.Name = sagaAttachment.descriptorName;
    fileDescriptor.Url = sagaAttachment.url;
    fileDescriptor.Version = sagaAttachment.version;
    fileDescriptor.VersionToken = sagaAttachment.versionToken;

    return fileDescriptor;
  }

  private onUploadEvent(uploadEvent: UploadEvent) {
    const uploadItem = uploadEvent.target as SagaFileUploadItem;

    switch (uploadEvent.type) {
      case UploadEventType.uploadAdded:
        this.onUploadItemAdded(uploadItem);
        break;

      case UploadEventType.uploadRemoved:
      case UploadEventType.uploadFinished:
        this.removeUploadItem(uploadItem);
        break;
    }
  }

  private onUploadItemAdded(uploadItem: SagaFileUploadItem) {
    const haveAttachment: boolean = this.sagaAttachments.find(sagaAttachment => sagaAttachment.fileUid === uploadItem.fileUid)
      ? true
      : false;

    if (!haveAttachment) {
      this.sagaAttachments.push(this.createSagaAttachment(uploadItem));
      this.saveUploads();
    }
  }

  private createSagaAttachment(uploadItem: SagaFileUploadItem): ISagaAttachment {
    return {
      versionToken: uploadItem.versionToken,
      version: uploadItem.version,
      fileUid: uploadItem.fileUid,
      descriptorName: uploadItem.descriptorName,
      url: uploadItem.url,
      callcard: uploadItem.parentId,
      localURL: uploadItem instanceof SagaFileEntryUploadItem ? uploadItem.localURL : null
    };
  }

  private removeUploadItem(uploadItem: SagaFileUploadItem) {
    const sagaAttachment: ISagaAttachment = this.sagaAttachments.find(cachedAttachment => cachedAttachment.fileUid === uploadItem.fileUid);
    this.sagaAttachments.splice(this.sagaAttachments.indexOf(sagaAttachment), 1);
    this.saveUploads();

    this.fileBridge.remove(sagaAttachment.localURL);
  }

  private saveUploads() {
    if ('resolveLocalFileSystemURL' in window) {
      this.fileBridge
        .getFileFrom(this.rootDirectory, this.UPLOADS_STORAGE_NAME)
        .then(file => this.fileBridge.writeFile(file, JSON.stringify(this.sagaAttachments)));
    }
  }

  moveFrom(customFile: ICustomFile): Promise<ICustomFile> {
    return this.fileSystemService.getRoot().then(rootDirectory => this.moveTo(rootDirectory, customFile));
  }

  private moveTo(root: DirectoryEntry, customFile: ICustomFile): Promise<ICustomFile> {
    // file has to be moved to another filesystem
    return customFile.uri.indexOf(root.nativeURL) === -1
      ? this.fileBridge
          .moveTo(root, customFile.uri, customFile.name)
          .then((fileEntry: FileEntry) => this.updateCustomFile(customFile, fileEntry))
      : this.fileBridge.getEntry(customFile.uri).then((fileEntry: FileEntry) => this.updateCustomFile(customFile, fileEntry));
  }

  private updateCustomFile(customFile: ICustomFile, entry: FileEntry): Promise<ICustomFile> {
    // Change uri to the new location
    customFile.uri = entry.nativeURL;
    return this.fileBridge
      .getFile(entry)
      .then(file => (customFile.file = file))
      .then(() => customFile);
  }
}
