<ion-header>
  <ion-toolbar color="light" id="generic-login-toolbar">
    <img class="icon" src="assets/icon.png" slot="start" />
    <app-setting-button slot="end"></app-setting-button>
  </ion-toolbar>
</ion-header>
<ion-content [ngSwitch]="view">
  <app-login-view *ngSwitchCase="loginView" [loginType]="loginType"></app-login-view>
  <app-login-config-selector *ngSwitchCase="roleView" [values]="config.roles"></app-login-config-selector>
  <app-login-config-selector *ngSwitchCase="profileView" [values]="config.profiles"></app-login-config-selector>
  <app-login-loading *ngSwitchDefault></app-login-loading>
</ion-content>
<ion-footer>
  <div class="error" *ngIf="hasError">
    <span [translate]="error"></span>
  </div>
</ion-footer>
