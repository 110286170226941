import { CurrentCardService, MyCardService } from '@addins/core/core';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { CallCard } from '@models/imported/SagaSchema/CallCard';
import { CallcardFilterService } from '@services/callcard-filter/callcard-filter.service';
import { Initializer } from '@services/initializer/initializer.service';
import { SectorService } from '@services/sector/sector.service';
import { AddinView } from '@techwan/ionic-core';
import { CallCardListBaseComponent } from '../../components/callcard-list-base/callcard-list-base.component';
import { CallCardsProviderService } from '../../services/callcards-provider/callcards-provider.service';
import { MenuService } from '../../services/executables/menu/menu.service';
import { LayoutViewStateService } from '../../services/layout-view-state/layout-view-state.service';

@AddinView({
  name: 'BannerView'
})
@Component({
  selector: 'app-callcard-list-banner',
  templateUrl: './callcard-list-banner.component.html',
  styleUrls: ['./callcard-list-banner.component.scss'],
  providers: [LayoutViewStateService]
})
export class CallcardListBannerComponent extends CallCardListBaseComponent implements OnInit, OnDestroy {
  private _filter: () => void = null;

  public get show(): boolean {
    return this._layoutViewStateService.isOpen;
  }

  constructor(
    public readonly myCard: MyCardService,
    public readonly current: CurrentCardService,
    protected readonly _initializer: Initializer,
    protected readonly _callCardProvider: CallCardsProviderService,
    private readonly _layoutViewStateService: LayoutViewStateService,
    private readonly _menuService: MenuService,
    private readonly _sectorService: SectorService,
    private readonly _callCardFilterService: CallcardFilterService
  ) {
    super(_initializer, _callCardProvider);
    this._layoutViewStateService.setup(this._menuService.menuEntry, this._cardsProvider);
  }

  public inMyArea(card: CallCard): boolean {
    return card.Owner === this._sectorService.mySector;
  }

  public inMyRegion(card: CallCard): boolean {
    return !this.inMyArea(card) && this._sectorService.myParents.indexOf(card.Owner) > -1;
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    if (this._filter !== null) {
      this._filter();
      this._filter = null;
    }
  }

  protected onComplete(c: boolean) {
    super.onComplete(c);
    if (c === true) {
      this._filter = this._callCardFilterService.onlyValid(this.cards);
    }
  }
}
