import { CardTitleFormatterService } from '@addins/core/core';
import { ILocalizeRequest, LocalizePositionService } from '@addins/core/map';
import { ICallcardListCustomComponent } from '@addins/listcallcards/public_api';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CallCard } from '@models/imported/SagaSchema/CallCard';
import { AppSettingProviderService } from '@services/settings';
import { AppSettings } from '@services/settings/schema/enums/app-settings';
import { Subject, Subscription } from 'rxjs';
import { CustomComponentsService } from '../../services/custom-components/custom-components.service';

@Component({
  selector: 'app-callcard-list-item',
  templateUrl: './callcard-list-item.component.html',
  styleUrls: ['./callcard-list-item.component.scss']
})
export class CallCardListItemComponent implements OnInit, OnDestroy {
  @Input()
  callcard: CallCard;
  @Input()
  isMyCallcard: boolean = false;
  @Input()
  cssClass?: string;

  get lines(): string {
    return this.isMyCallcard ? 'none' : undefined;
  }

  get callcardTitle(): string {
    return this.titleFormatter.format(this.callcard);
  }

  private _locationIconSize: string = 'large';
  get locationIconSize(): string {
    return this._locationIconSize;
  }

  customComponents: ICallcardListCustomComponent[] = [];
  customSlotComponents: ICallcardListCustomComponent[] = [];

  private localizeCallcardEvent: Subject<ILocalizeRequest>;
  private localizeCallcardSubscription: Subscription = null;

  constructor(
    private titleFormatter: CardTitleFormatterService,
    private localizePositionService: LocalizePositionService,
    private customComponentsService: CustomComponentsService,
    private appSettingsProvider: AppSettingProviderService
  ) {}

  ngOnInit() {
    this.localizeCallcardEvent = new Subject<ILocalizeRequest>();
    this.localizeCallcardSubscription = this.localizePositionService.listenForLocation(this.localizeCallcardEvent);

    this.customComponents = this.customComponentsService.customComponents.filter(component => !component.slot);
    this.customSlotComponents = this.customComponentsService.customComponents.filter(component => component.slot);

    this.appSettingsProvider.getBooleanSetting(AppSettings.LargeFontEnabled, false, true).subscribe(appSetting => {
      this._locationIconSize = appSetting.current ? 'xlarge' : 'large';
    });
  }

  ngOnDestroy() {
    this.localizeCallcardSubscription.unsubscribe();
    this.localizeCallcardSubscription = null;
  }

  onLocalize($event: MouseEvent | TouchEvent) {
    $event.stopPropagation();
    $event.preventDefault();

    this.localizeCallcardEvent.next({
      positions: [this.callcard.location],
      target: null
    });
  }
}
