<ion-content>
  <ion-list>
    <app-generic-settings></app-generic-settings>

    <app-custom-settings *ngFor="let component of customComponents" [component]="component"></app-custom-settings>

    <app-location-settings [readOnly]="readOnly"></app-location-settings>

    <app-connectivity-settings></app-connectivity-settings>
  </ion-list>
</ion-content>
