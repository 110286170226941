import { JournalTool } from '@addins/singlecallcard-journal';
import { CallCard } from '@models/imported/SagaSchema/CallCard';

export class AddFreeTextTool extends JournalTool {
  constructor(private executor: Function, private isEnabledFn: (callcard) => boolean) {
    super('Mobile.AddStamp', '', '', 0, executor);
  }

  isEnabledFor(callcard: CallCard): boolean {
    return this.isEnabledFn(callcard);
  }
}
