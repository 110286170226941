import { Component, Input, OnInit } from '@angular/core';
import { ListSettingValue } from '../../schema/list-setting-value';
import { LocationSettings } from '../../schema/location-settings';
import { LocationSettingsManagerService } from '../../services/location-settings-manager/location-settings-manager.service';
import { ListSettingWithNames } from '../../views/settings-editor/settings-editor.component';

@Component({
  selector: 'app-location-settings',
  templateUrl: './location-settings.component.html',
  styleUrls: ['./location-settings.component.scss']
})
export class LocationSettingsComponent implements OnInit {
  @Input()
  readOnly: boolean = false;

  private _gpsSettings: LocationSettings;
  get gpsSettings(): LocationSettings {
    return this._gpsSettings;
  }

  private _gpsFilterSettings: ListSettingWithNames[] = [];
  get gpsFilterSettings(): ListSettingWithNames[] {
    return this._gpsFilterSettings;
  }

  get gpsEnabled(): boolean {
    return this._gpsSettings.isLocationTrackingEnabled;
  }

  set gpsEnabled(enabled: boolean) {
    this._gpsSettings.setLocationTrackingEnabled(enabled);
  }

  constructor(private locationService: LocationSettingsManagerService) {}

  ngOnInit() {
    this._gpsSettings = this.locationService.settings;

    this.addGpsFilterSetting(this._gpsSettings.getDistanceFilterObject(), false);
    this.addGpsFilterSetting(this._gpsSettings.getServerUpdateObject(), false);
  }

  private addGpsFilterSetting(setting: ListSettingValue, displayNames: Object = {}): void {
    this._gpsFilterSettings.push({ setting, displayNames, readonly: false });
  }

  onGpsToggleChange($event) {
    this._gpsSettings.setLocationTrackingEnabled($event.detail.checked);
  }
}
