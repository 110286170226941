import { Injectable } from '@angular/core';
import { CallCard } from '@models/imported/SagaSchema/CallCard';
import { List } from '@models/list';
import { filter, first } from 'rxjs/operators';
import { SagaSettingsService } from '@services/settings';

@Injectable({
  providedIn: 'root'
})
export class CallcardFilterService {
  private _excludedDispositionCodes: number[] = [];

  constructor(private sagaSettingsService: SagaSettingsService) {
    this.sagaSettingsService.$ready
      .pipe(
        filter(ready => ready),
        first()
      )
      .subscribe(() => {
        this.initExcludedDispositionCodes();
      });
  }

  private initExcludedDispositionCodes(): void {
    const setting = this.sagaSettingsService.getValue('SagaMobileWebClient.CardListFieldsFiltering');
    if (setting) {
      const parsedSetting = JSON.parse(setting);
      const filterSettingValue = parsedSetting.DispositionCode;
      if (filterSettingValue instanceof Array) {
        filterSettingValue.forEach(item => {
          this._excludedDispositionCodes.push(item);
        });
      } else {
        this._excludedDispositionCodes.push(filterSettingValue);
      }
    }
  }

  public isAllowedDispositionCode(callCard: CallCard): boolean {
    return callCard.event !== null && !this._excludedDispositionCodes.includes(callCard.event.DispositionCode);
  }

  onlyValid(callcards: List<CallCard>): () => void {
    return callcards.addFilter(callcard => callcard.event !== null && callcard.event.EventText !== '' && callcard.location !== null);
  }
}
