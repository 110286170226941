import { NgModule } from '@angular/core';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import { AppPreferences } from '@ionic-native/app-preferences/ngx';
import { BackgroundMode } from '@ionic-native/background-mode/ngx';
import { Camera } from '@ionic-native/camera/ngx';
import { Device } from '@ionic-native/device/ngx';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { FileTransfer } from '@ionic-native/file-transfer/ngx';
import { File } from '@ionic-native/file/ngx';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { Globalization } from '@ionic-native/globalization/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { LaunchNavigator } from '@ionic-native/launch-navigator/ngx';
import { LocalNotifications } from '@ionic-native/local-notifications/ngx';
import { NativeStorage } from '@ionic-native/native-storage/ngx';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Vibration } from '@ionic-native/vibration/ngx';
import { Zip } from '@ionic-native/zip/ngx';

@NgModule({
  providers: [
    Device,
    Globalization,
    AppPreferences,
    AndroidPermissions,
    File,
    StatusBar,
    SplashScreen,
    BackgroundMode,
    Vibration,
    LocalNotifications,
    Geolocation,
    FileTransfer,
    LaunchNavigator,
    InAppBrowser,
    FileOpener,
    Camera,
    NativeStorage,
    Zip
  ]
})
export class NgxImports {}
