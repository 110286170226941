import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ICustomFilter } from '../../schema/interfaces/ICustomFilter';
import { CallCardsCustomFiltersService } from '../../services/callcards-custom-filters/callcards-custom-filters.service';

@Component({
  selector: 'app-callcards-custom-filters',
  templateUrl: './callcards-custom-filters.component.html',
  styleUrls: ['./callcards-custom-filters.component.scss']
})
export class CallcardsCustomFiltersComponent implements OnInit {
  customFilters: ICustomFilter[];
  openedAccordions: string[];

  constructor(private callcardsCustomFilters: CallCardsCustomFiltersService, private modalController: ModalController) {}

  ngOnInit() {
    this.customFilters = this.callcardsCustomFilters.filters;
    if (this.customFilters.length > 0) {
      this.openedAccordions = [this.customFilters[0].name];
    }
  }

  onDismiss() {
    this.modalController.dismiss();
  }

  onSubmit() {
    this.customFilters.forEach(customFilter => customFilter.save());
    this.modalController.dismiss(true);
  }
}
