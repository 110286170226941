import { Inject, Injectable } from '@angular/core';
import { IonNav, Platform, ToastController } from '@ionic/angular';
import { Globalization } from '@ionic-native/globalization/ngx';
import { Device } from '@ionic-native/device/ngx';
import { AppPreferences } from '@ionic-native/app-preferences/ngx';
import { from, Observable, of, zip } from 'rxjs';
import { map } from 'rxjs/operators';
import { INativePreferences } from '../../schema/interfaces/INativePreferences';
import { NATIVE_PREFERENCE_TOKEN } from '../../schema/injector';
import { TranslateService } from '@ngx-translate/core';

export interface SettingEntry {
  text: string;
  icon?: string;
  handler?: (event?: Event, navCtrl?: IonNav) => void;
  type?: string;
  config?: any;
  value?: any;
}

/**
 * @description
 * Service used to manage settings
 */
@Injectable()
export class AppSettingsService {
  private _settingsEntry: any = {};

  private _availableLanguages = ['en', 'fr', 'ar'];
  private _defaultLanguage = 'fr';

  constructor(
    private readonly platform: Platform,
    private readonly device: Device,
    private readonly globalization: Globalization,
    private readonly settings: AppPreferences,
    @Inject(NATIVE_PREFERENCE_TOKEN)
    private readonly preferences: INativePreferences,
    private readonly _toastCtrl: ToastController,
    private readonly _translateService: TranslateService
  ) {}

  public addSettingsEntry(entry: SettingEntry, group: string = 'global'): void {
    group = group.toLowerCase();
    if (!this._settingsEntry[group]) {
      this._settingsEntry[group] = [];
    }

    this._settingsEntry[group].push(entry);
  }

  public getGroups(): string[] {
    return Object.keys(this._settingsEntry);
  }

  public getSettingsForGroup(group: string = 'global'): SettingEntry[] {
    return this._settingsEntry[group.toLowerCase()] || [];
  }

  public baseUrl(): Observable<string> {
    return this.get('ServerURL', '').pipe(map((baseUrl: string) => (baseUrl && !baseUrl.endsWith('/') ? baseUrl + '/' : baseUrl)));
  }

  public apiKey(): Observable<string> {
    return this.platform.is('cordova') ? from(this.settings.fetch('ApiKey')) : of('');
  }

  public apiBaseUrl(): Observable<string> {
    return !this.platform.is('cordova') || this.device.platform === 'browser' ? of('') : this.baseUrl();
  }

  public updateBaseUrl(): Observable<string> {
    return this.device.platform === 'browser' ? this.apiBaseUrl().pipe(map(x => x + 'api/')) : this.baseUrl();
  }

  public getPlatformLanguage(): Observable<string> {
    return window.hasOwnProperty('cordova')
      ? from(this.globalization.getPreferredLanguage()).pipe(map(val => val.value.substr(0, 2)))
      : of(navigator.language.substr(0, 2));
  }

  public cameraQuality(): Observable<number> {
    return !this.platform.is('cordova')
      ? of(parseInt(this.getSync('camera_quality', '80'), 10))
      : from(this.settings.fetch('camera_quality')).pipe(map((quality: string) => parseInt(quality, 10) || 80));
  }

  public saveToAlbum(): Observable<boolean> {
    return from(this.settings.fetch('save_to_album')).pipe(map((saveToAlbum: string) => saveToAlbum === 'true' || true));
  }

  public language(skipPlatform: boolean = false): Observable<string> {
    return zip(this.get('lang', 'platform'), this.getPlatformLanguage())
      .pipe(
        map(([language, platform]) => {
          // Selected language is same as platform
          if (language === 'platform' && !skipPlatform) {
            return platform;
          } else if (language !== 'platform') {
            language = language.substr(0, 2);
          }

          return language || this._defaultLanguage;
        })
      )
      .pipe(
        map(lang => {
          // Check if language is available, or change it to the default one
          if (!this._availableLanguages.includes(lang)) {
            this._toastCtrl
              .create({
                message: this._translateService.instant('SagaMobile.Translation.Unavailable', {
                  wantedLanguage: lang,
                  defaultLanguage: this._defaultLanguage
                }),
                duration: 3000
              })
              .then(ionToast => ionToast.present());
            lang = this._defaultLanguage;
          }
          return lang;
        })
      );
  }

  public getSync(name: string, def: any) {
    return localStorage.getItem(name) || def;
  }

  public get(name: string, def: any, forceLocalStorage: boolean = false): Observable<any> {
    return !this.platform.is('cordova') || forceLocalStorage
      ? of(localStorage.getItem(name) || def)
      : zip(from(this.settings.fetch(name)), this.preferences.fetch(name)).pipe(map(([s1, s2]) => (s2 && s2[name]) || s1 || def));
  }

  public set(name: string, value: any, forceLocalStorage: boolean = false): Observable<any> {
    return !this.platform.is('cordova') || forceLocalStorage
      ? of(localStorage.setItem(name, value))
      : of(this.preferences.store(name, value));
  }
}
