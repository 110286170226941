import { Component, OnInit } from '@angular/core';
import { SagaSettingsService } from '@services/settings/saga-settings/saga-settings.service';
import { AddinView } from '@techwan/ionic-core';
import { ListSettingValue } from '../../schema/list-setting-value';
import { CustomComponentsService } from '../../services/custom-components/custom-components.service';

export class ListSettingWithNames {
  setting: ListSettingValue;
  displayNames: Object;
  readonly: boolean;
}

@Component({
  selector: 'app-settings-editor',
  templateUrl: './settings-editor.component.html',
  styleUrls: ['./settings-editor.component.scss']
})
@AddinView({
  name: 'SettingsEditor'
})
export class SettingsEditorComponent implements OnInit {
  private _readOnly: boolean = false;
  get readOnly(): boolean {
    return this._readOnly;
  }

  get customComponents() {
    return this.customSettingsComponents.customComponents;
  }

  constructor(private settingsService: SagaSettingsService, private customSettingsComponents: CustomComponentsService) {}

  ngOnInit() {
    this.setup();
  }

  private setup(): void {
    this._readOnly = this.settingsService.getValue('SagaMobileWebClient.SettingsRO') || false;
  }
}
