import { CurrentCardService, MyCardService, SoundService } from '@addins/core/core';
import { Injectable } from '@angular/core';
import { Vibration } from '@ionic-native/vibration/ngx';
import { AlertController, ToastController } from '@ionic/angular';
import { GuidTools } from '@models/guid';
import { UnitActivity } from '@models/imported/SagaSchema/UnitActivity';
import { UnitActivityResource } from '@models/imported/SagaSchema/UnitActivityResource';
import { ListResourceStatus } from '@models/imported/SagaStateLib/ListResourceStatus';
import { TranslateService } from '@ngx-translate/core';
import { getAddinParameter } from '@schema/environment';
import { AssociationSecurity } from '@services/association-security/association-security.service';
import { CacheService } from '@services/cache/cache.service';
import { CacheAction, CacheEvent } from '@services/cache/cacheEvent';
import { CompletionHandlerDelegate, ICompletionDelegate } from '@services/initializer/completion-handler-delegate';
import { Initializer } from '@services/initializer/initializer.service';
import { SagaSettingsService } from '@services/settings';
import { LocationBasedStatusService } from '@services/status/location-based-status/location-based-status.service';
import { MyUnitService } from '@services/unit-activity/my-unit/my-unit.service';
import { UnitActivityService } from '@services/unit-activity/unit-activity.service';
import { AddinExecute, DeviceService, Execute } from '@techwan/ionic-core';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

interface IDialog {
  message: string;
  loader: Promise<HTMLIonAlertElement>;
  inst: HTMLIonAlertElement;
}

@AddinExecute({
  name: 'UnitActivity'
})
@Injectable()
export class UnitActivityAddinService extends Execute implements ICompletionDelegate {
  private _lastUnitStatus: ListResourceStatus = null;
  private _lastCallcardId: string = null;

  private _completion: CompletionHandlerDelegate = null;
  private _subs: Subscription[] = [];
  private _dialog: IDialog = null;

  private get unitActivity(): UnitActivity {
    return this._unit.mine;
  }

  constructor(
    private _initializer: Initializer,
    private _cacheService: CacheService,
    private _associationSecurity: AssociationSecurity,
    private _toastCtrl: ToastController,
    private _unitActivityService: UnitActivityService,
    private _alertCtrl: AlertController,
    private _translate: TranslateService,
    private _unit: MyUnitService,
    private _myCard: MyCardService,
    private _vibration: Vibration,
    private _device: DeviceService,
    private _card: CurrentCardService,
    private _soundService: SoundService,
    private _sagaSettings: SagaSettingsService,
    private _locationBasedStatusChangeService: LocationBasedStatusService
  ) {
    super();
  }

  execute(): void {
    this._completion = new CompletionHandlerDelegate(this._initializer, this);
  }

  $onComplete(): void {
    this._completion = null;
    this._lastUnitStatus = this.unitActivity && this.unitActivity.status;
    this._lastCallcardId = (this._myCard.myCard && this._myCard.myCard.ObjGuid) || null;
    this._unit.$unitChanged.subscribe(unitActivity => this.onUnitChanged(unitActivity));

    if (this._sagaSettings.getValue('SagaMobileWebClient.AutoOnSite') === true) {
      this._locationBasedStatusChangeService.setup();
    }

    this._unitActivityService.onStatusChange.emit(this._lastUnitStatus);
    this._cacheService.$events
      .pipe(filter(change => change.object.$t === UnitActivityResource.$t))
      .subscribe((event: CacheEvent<UnitActivityResource>) => {
        const device = this._associationSecurity.getDevice();
        if (event.object.ResourceUId === device.ObjGuid) {
          if (event.action === CacheAction.removed) {
            this.destroyUnit(false);
          }
        }
      });
  }

  private onUnitChanged(unit: UnitActivity) {
    if (unit === null) {
      this.clear();
      this.destroyUnit();
    } else if (this._subs.length === 0) {
      this.setup();
    }

    // Dismiss the alert dialog
    if (unit !== null && this._dialog !== null && this._dialog.inst !== null) {
      this._dialog.inst.dismiss().then(() => (this._dialog = null));
    }
  }

  private clear() {
    while (this._subs.length > 0) {
      this._subs.pop().unsubscribe();
    }
  }

  private destroyUnit(uaDestroyed = true) {
    const message = this._translate.instant(uaDestroyed ? 'SagaMobile.UA.Destroy' : 'SagaMobile.UA.ResourceRemoved');
    if (this._dialog === null) {
      this._vibration.vibrate([1000, 500, 1000]);
      this._dialog = {
        message,
        inst: null,
        loader: this._alertCtrl
          .create({ message, backdropDismiss: false, buttons: [this._translate.instant('Mobile.Close')] })
          .then(ionAlert => (this._dialog.inst = this.refresh(ionAlert)).present().then(() => ionAlert.onWillDismiss()))
          .then(() => this._device.fetch())
          .then(() => (this._dialog = null))
      };
    } else if (this._dialog.inst !== null) {
      this._dialog.inst.message = message;     
    } else {
      this._dialog.message = message;
    }
  }

  private refresh(ionAlert: HTMLIonAlertElement): HTMLIonAlertElement {
    if (this._dialog.message !== ionAlert.message) {
      ionAlert.message = this._dialog.message;
    }
    return ionAlert;
  }

  private setup() {
    this._subs.push(
      this._cacheService.$events
        .pipe(filter(change => change.object.$t === UnitActivity.$t))
        .subscribe((event: CacheEvent<UnitActivity>) => {
          const item = event.object,
            action = event.action;
          if (item.ObjGuid === this.unitActivity.ObjGuid && action !== CacheAction.removed) {
            // Check status changed
            if (this._lastUnitStatus !== item.status) {
              this._unitActivityService.onStatusChange.emit(item.status);
              this._lastUnitStatus = item.status;

              if (this._sagaSettings.getValue('SagaMobileWebClient.PlaySoundWhenStatusChanged') === true) {
                this._soundService.playNotification();
              }
            }

            // Check callcard change
            const currentCallCardId = !GuidTools.isEmpty(item.CallCardID) ? item.CallCardID : null;
            if (this._lastCallcardId !== currentCallCardId) {
              this._lastCallcardId = currentCallCardId;
              this._unitActivityService.onCardChange.emit(item.callcard);

              if (item.callcard && getAddinParameter('callcards').displayChangeCard) {
                this._toastCtrl
                  .create({
                    header: this._translate.instant('Saga.CardShared'),
                    position: 'bottom',
                    duration: 5000,
                    buttons: [
                      {
                        side: 'start',
                        icon: 'open',
                        text: 'Ouvrir',
                        handler: () => this._card.select(this.unitActivity.callcard)
                      },
                      {
                        text: 'Ok',
                        role: 'cancel',
                        handler: () => {}
                      }
                    ]
                  })
                  .then(ionToast => ionToast.present());
              } else if (currentCallCardId) {
                this._toastCtrl
                  .create({
                    header: this._translate.instant('Saga.AssignementReceive'),
                    position: 'bottom',
                    duration: 5000,
                    buttons: [
                      {
                        text: 'Ok',
                        role: 'cancel',
                        handler: () => {}
                      }
                    ]
                  })
                  .then(ionToast => ionToast.present());
              }
            } else {
              this._toastCtrl
                .create({
                  header: this._translate.instant('Saga.UnitUpdated'),
                  position: 'bottom',
                  duration: 5000,
                  buttons: [
                    {
                      text: 'Ok',
                      role: 'cancel',
                      handler: () => {}
                    }
                  ]
                })
                .then(ionToast => ionToast.present());
            }
          }
        })
    );
  }
}
