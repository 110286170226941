import { Injectable } from '@angular/core';
import { WazeProviderService } from '../waze-provider/waze-provider.service';
import { SygicProviderService } from '../sygic-provider/sygic-provider.service';
import { TomTomProviderService } from '../tom-tom-provider/tom-tom-provider.service';
import { GoogleMapsProviderService } from '../google-maps-provider/google-maps-provider.service';
import { ProviderName } from '../../schema/provider-name';
import { INavigationProvider } from '../../schema/interfaces/INavigationProvider';
import { SagaSettingsService } from '@services/settings';

@Injectable()
export class ExternalProviderFactoryService {
  private _itineraryProviders: string[];

  constructor(
    private readonly sygic: SygicProviderService,
    private readonly tomTom: TomTomProviderService,
    private readonly waze: WazeProviderService,
    private readonly google: GoogleMapsProviderService,
    private readonly settings: SagaSettingsService
  ) {}

  getProviders(): string[] {
    if (!this._itineraryProviders) {
      this._itineraryProviders = this.getItineraryProviders();
    }
    return this._itineraryProviders;
  }

  private getItineraryProviders(): string[] {
    return (this.settings.getValue('SagaMobileWebClient.ExternalItineraryProvider') || 'sygic')
      .split(',')
      .filter(providerName => ProviderName[providerName] !== undefined);
  }

  get(providerName: ProviderName): INavigationProvider {
    switch (providerName) {
      case ProviderName.sygic:
        return this.sygic;

      case ProviderName.tomtom:
        return this.tomTom;

      case ProviderName.waze:
        return this.waze;

      case ProviderName.googlemaps:
        return this.google;
    }
  }
}
