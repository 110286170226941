import { Injectable, Type } from '@angular/core';
import { LayoutService } from '@services/layout/layout.service';
import { ProfileReaderService } from '@services/profile-reader/profile-reader.service';
import { ProfileService } from '@services/profile/profile.service';
import { ViewSelectorService } from '@services/view-selector/view-selector.service';
import { LAYOUT_VIEW_NAME, SINGLECALLCARD } from '../../schema/constants';

const CARD_VIEW_KEY = 'card-view';

@Injectable()
export class ProfileParserService {
  constructor(
    private profileService: ProfileService,
    private profileReaderService: ProfileReaderService,
    private layoutService: LayoutService,
    private viewSelectorService: ViewSelectorService
  ) {}

  shouldResetCurrentCard(): boolean {
    return this.hasSingleCallcardView();
  }

  private hasSingleCallcardView(): boolean {
    return this.layoutService.mainLayoutHasView(`${SINGLECALLCARD}/${LAYOUT_VIEW_NAME}`);
  }

  shouldSelectCurrentCard(): boolean {
    return this.hasSingleCallcardView();
  }

  getCardView(): Type<any> {
    const component = this.profileReaderService.getComponent(this.profileService.current, SINGLECALLCARD);
    const viewName = (component && component.config && component.config[CARD_VIEW_KEY]) || null;
    const addin = (viewName && this.viewSelectorService.getView(`${SINGLECALLCARD}/${viewName}`)) || null;
    return (addin && addin.element) || null;
  }
}
