import { MapName } from '@addins/core/core';
import { PanelContent, PanelContentBaseComponent } from '@addins/core/panel';
import { Component, ElementRef, OnInit } from '@angular/core';
import { MapInstanceEvent, MapInstanceEventType, MapInstancesService } from '@services/map';
import { SagaLayerManager } from '@techwan/ionic-core/map';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-layers-panel-content',
  templateUrl: './layers-panel-content.component.html',
  styleUrls: ['./layers-panel-content.component.scss']
})
export class LayersPanelContentComponent extends PanelContentBaseComponent implements OnInit, PanelContent {
  private _layers: SagaLayerManager;
  get layers(): SagaLayerManager {
    return this._layers;
  }

  private _sub: Subscription = null;

  constructor(private readonly elem: ElementRef, private readonly maps: MapInstancesService) {
    super();
  }

  ngOnInit(): void {
    const map = this.maps.get(MapName.main);
    if (map !== null) {
      this._layers = map.getLayerManager() as SagaLayerManager;
    } else {
      this._sub = this.maps.$change
        .pipe(filter(event => event.name === MapName.main && event.type === MapInstanceEventType.added))
        .subscribe(e => this.$mapChanged(e));
    }
  }

  private $mapChanged(e: MapInstanceEvent): void {
    this._sub.unsubscribe();
    this._sub = null;
    this._layers = e.target.getLayerManager() as SagaLayerManager;
  }
}
