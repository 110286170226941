import { MapName, MyCardService } from '@addins/core/core';
import { Injectable } from '@angular/core';
import { isValid } from '@models/guid';
import { CallCard } from '@models/imported/SagaSchema/CallCard';
import { TranslateService } from '@ngx-translate/core';
import { CacheService, CacheState } from '@services/cache/cache.service';
import { CallcardFilterService } from '@services/callcard-filter/callcard-filter.service';
import { CallcardService } from '@services/callcard/callcard.service';
import { CompletionHandlerDelegate, ICompletionDelegate } from '@services/initializer/completion-handler-delegate';
import { Initializer } from '@services/initializer/initializer.service';
import { MapInstancesService, MapService } from '@services/map';
import { SagaSettingsService } from '@services/settings';
import { LocalizableObjectLayer, ObjectFeature, Map as SagaMap } from '@techwan/mapping';
import { Subscription } from 'rxjs';
import { filter, first } from 'rxjs/operators';
import { ILayerController } from '../../schema/interfaces/ILayerController';
import { FeatureStyleService } from '../feature-style/feature-style.service';
import { LocalizePositionService } from '../localize-position/localize-position.service';

@Injectable()
export class CardsLayerService implements ICompletionDelegate, ILayerController {
  private CARD_TYPE_NAME: string;
  private _sagaMap: SagaMap = null;
  private _callcardLayer: LocalizableObjectLayer = null;
  private _myCallcardLayer: LocalizableObjectLayer = null;

  private _subs: Subscription[] = [];

  private removeButton: () => void = null;

  private _completion: CompletionHandlerDelegate;

  constructor(
    private _initializer: Initializer,
    private _cache: CacheService,
    private _mapService: MapService,
    private _translate: TranslateService,
    private _callcardService: CallcardService,
    private _sagaSettings: SagaSettingsService,
    private _featureStyle: FeatureStyleService,
    private _myCard: MyCardService,
    private _mapInstances: MapInstancesService,
    private _localize: LocalizePositionService,
    private _callCardFilterService: CallcardFilterService
  ) {
    this._sagaSettings.$ready
      .pipe(
        filter(ready => ready),
        first()
      )
      .subscribe(() => {
        this.CARD_TYPE_NAME = this._sagaSettings.getValue('SagaMobileWebClient.CardTypeName');
      });
  }

  mapDidChange(sagaMap: SagaMap) {
    if (sagaMap && this._sagaMap === null) {
      this._sagaMap = sagaMap;
      this.setup();
    } else if (!sagaMap) {
      this.cleanup();
      this._sagaMap = null;
    }
  }

  private setup() {
    this.createCallcardLayers();

    this._subs.push(this._callcardService.onCardDisplay.subscribe(callCard => this.centerOnCard(callCard)));
    this._subs.push(
      this._cache.state
        .pipe(
          filter(cacheState => cacheState === CacheState.ready),
          first()
        )
        .subscribe(() => {
          this._subs.push(
            this._cache.$events.pipe(filter(event => event.object.$t === this.CARD_TYPE_NAME)).subscribe(() => this.updateCallCards())
          );
          this.updateCallCards();
        })
    );
  }

  private createCallcardLayers() {
    this._callcardLayer = this._sagaMap.createLocalizableObjectLayer({
      cluster: false,
      updateWhileAnimating: false,
      updateWhileInteracting: false
    });

    this._myCallcardLayer = this._sagaMap.createLocalizableObjectLayer({
      cluster: false,
      updateWhileAnimating: false,
      updateWhileInteracting: false,
      zIndex: 1
    });
  }

  private cleanup() {
    while (this._subs.length) {
      this._subs.pop().unsubscribe();
    }

    this.clearCallcardLayers();
    this._callcardLayer = null;
    this._myCallcardLayer = null;

    if (this.removeButton) {
      this.removeButton();
      this.removeButton = null;
    }
  }

  private clearCallcardLayers() {
    this._callcardLayer.clearObjects();
    this._myCallcardLayer.clearObjects();
  }

  /**
   * Update the list of call card showed in the object layer
   */
  private updateCallCards(): void {
    const map = this._mapInstances.get(MapName.main);
    if (map !== null) {
      map.addReadyCallback(() => {
        this.clearCallcardLayers();
        this._completion = new CompletionHandlerDelegate(this._initializer, this);
      });
    }
  }

  $onComplete() {
    this._completion = null;
    if (this._myCard.myCard && this.removeButton === null) {
      this.removeButton = this._mapService.addButtonItems({
        icon: 'document',
        context: this,
        handler: () => this.centerOnCard(this._myCard.myCard)
      });
    } else if (!this._myCard.myCard && this.removeButton) {
      this.removeButton();
      this.removeButton = null;
    }
    this.addCards(this._cache.getListByTypeName(this.CARD_TYPE_NAME));
  }

  private centerOnCard(callcard): void {
    if (callcard) {
      this._localize.centerOnLocation(this._sagaMap.getView(), callcard.location, 1);
    }
  }

  /**
   * Add a cards to the object layer
   */
  private addCards(cards: CallCard[]): void {
    const myCard = this._myCard.myCard;
    const showMyCardOnly = this._sagaSettings.getValue('SagaMobileWebClient.MapShowMyCardOnly');
    if (showMyCardOnly) {
      cards = cards.filter(card => myCard && myCard.ObjGuid === card.ObjGuid);
    }

    cards = cards.filter(callCard => this._callCardFilterService.isAllowedDispositionCode(callCard));

    cards.forEach(card => this.addCardToLayer(this._callcardLayer, card));

    if (myCard) {
      this.addCardToLayer(this._myCallcardLayer, myCard);
    }
  }

  private addCardToLayer(layer: LocalizableObjectLayer, card: CallCard) {
    let isCardAddedToLayer: boolean = false;
    const coordinate = card.location.getOLCoordinate();
    if (coordinate) {
      const feature: ObjectFeature = layer.addObject(card.ObjGuid, coordinate, card.location.coordinate.epsg);
      if (feature) {
        feature.setData('object', card);
        feature.set('id', card.ObjGuid);
        this._featureStyle.applyCardStyle(
          feature,
          card,
          card.rShort(),
          card.colorRgb,
          isValid(this._myCard.myCardId) && this._myCard.myCardId === card.ObjGuid
        );
        isCardAddedToLayer = true;
      }
    }
    if (!isCardAddedToLayer) {
      console.warn(`Card could not be added to the map (id: ${card.ObjGuid})`);
    }
  }
}
