import { Component, Input, OnInit } from '@angular/core';
import { TaskTypes } from '@models/imported/SagaBase';
import { Task } from '@models/imported/SagaSchema/Task';
import { UnitActivity } from '@models/imported/SagaSchema/UnitActivity';
import { CacheService } from '@services/cache/cache.service';
import { TaskUnitsProviderService } from '../../services/task-units-provider/task-units-provider.service';

@Component({
  selector: 'app-task-details-item',
  templateUrl: './task-details-item.component.html',
  styleUrls: ['./task-details-item.component.scss']
})
export class TaskDetailsItemComponent implements OnInit {
  @Input()
  task: Task;

  parentTask: Task;

  get units(): UnitActivity[] {
    return this.taskUnitsProvider.units;
  }

  constructor(private cache: CacheService, private taskUnitsProvider: TaskUnitsProviderService) {}

  ngOnInit() {
    if (this.task.TaskType == TaskTypes.Task) {
      this.parentTask = this.cache.getObjectByUid(this.task.ParentTaskUid);
    }
  }
}
