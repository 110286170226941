import { ILocalizeRequest, LocalizePositionService, MapSettingsService } from '@addins/core/map';
import { Component, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Guid } from '@models/guid';
import { CLocation } from '@models/imported/SagaSchema/CLocation';
import { UnitActivity } from '@models/imported/SagaSchema/UnitActivity';
import { Observable, Subject, Subscription } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { IChildAwareResource } from '../../schema/interfaces/IChildAwareResource';
import { UnitResourceProviderService } from '../../services/unit-resource-provider/unit-resource-provider.service';

@Component({
  selector: 'app-unit-activity-collapsible-view',
  templateUrl: './unit-activity-collapsible-view.component.html',
  styleUrls: ['./unit-activity-collapsible-view.component.scss']
})
export class UnitActivityCollapsibleViewComponent implements OnInit, OnDestroy {
  @Input()
  unit: UnitActivity;

  @Input()
  opened: Boolean;

  private _open = new Subject<Guid>();

  @Output()
  get $open(): Observable<Guid> {
    return this._open.asObservable();
  }

  resources: IChildAwareResource[];

  iconName: string = '';

  private localizeUnitEvent: Subject<ILocalizeRequest> = new Subject();

  private subs: Subscription[] = [];

  constructor(
    private resourceService: UnitResourceProviderService,
    private localizePosition: LocalizePositionService,
    private mapSettings: MapSettingsService
  ) {}

  ngOnInit() {
    this.subs.push(this.localizePosition.listenForLocation(this.localizeUnitEvent.asObservable()));
    this.subs.push(
      this.resourceService.$unitChangedEvent.pipe(filter(unitId => unitId === this.unit.ObjGuid)).subscribe(() => this.refreshResources())
    );
    this.refreshResources();
  }

  private refreshResources() {
    this.getResources().subscribe(() => this.setIcon());
    this.resourceService.getMobilePositions().subscribe();
  }

  private getResources(): Observable<IChildAwareResource[]> {
    return this.resourceService.getResourcesFor(this.unit.ObjGuid).pipe(tap(res => (this.resources = res)));
  }

  ngOnDestroy() {
    while (this.subs.length > 0) {
      this.subs.pop().unsubscribe();
    }
  }

  private setIcon(): void {
    const mainResource = this.resources.find(resource => resource.resource.ObjGuid == this.unit.MainResourceUId);

    if (!mainResource) {
      return;
    }

    this.iconName = UnitResourceProviderService.getResourceIconName(mainResource.resource.$t);
  }

  localize($event): void {
    this.localizeUnitEvent.next({ positions: [this.getLocation()], target: null });
    $event.preventDefault();
    $event.stopPropagation();
  }

  private getLocation(): CLocation {
    const [x, y] = this.resourceService.getPositionFor(this.unit.ObjGuid);
    const location = new CLocation();
    location.CenterX = x;
    location.CenterY = y;
    location.CoordEpsgCode = this.mapSettings.getSystemProjection();
    return location;
  }

  mainClick($event): void {
    if (!this.resources) {
      this.refreshResources();
    }
    this._open.next(this.unit.ObjGuid);
  }
}
