import { IAppSetting } from '@addins/core/core';
import { Directive, ElementRef, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { AppSettingProviderService } from '@services/settings';
import { AppSettings } from '@services/settings/schema/enums/app-settings';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[darkMode]'
})
export class DarkModeDirective implements OnInit, OnDestroy {
  private darkModeSetting: IAppSetting<boolean> = null;
  private changeSub: Subscription = null;

  constructor(private elementRef: ElementRef, private renderer: Renderer2, private appSettings: AppSettingProviderService) {}

  ngOnInit() {
    this.appSettings.getBooleanSetting(AppSettings.DarkModeEnabled, false, true).subscribe(appSetting => this.update(appSetting));
  }

  private update(value: IAppSetting<boolean>) {
    this.darkModeSetting = value;
    this.changeSub = this.darkModeSetting.$change.subscribe(() => this.refresh());
  }

  private refresh() {
    if (this.darkModeSetting.current) {
      this.renderer.addClass(this.elementRef.nativeElement, 'dark');
    } else {
      this.renderer.removeClass(this.elementRef.nativeElement, 'dark');
    }
  }

  ngOnDestroy() {
    if (this.changeSub) {
      this.changeSub.unsubscribe();
      this.changeSub = null;
    }
  }
}
