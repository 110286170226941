<ng-container *ngIf="parentTask">
  <ion-item>
    <ion-label class="ion-text-wrap">
      <h2>
        <strong>{{ parentTask.Name }}</strong>
      </h2>
    </ion-label>
  </ion-item>
  <ion-item>
    <ion-label class="ion-text-wrap">
      <h3>
        {{ parentTask.StartDateUTC | sagaDate: 'SubmittedDateFormat' }} - {{ parentTask.EndDateUTC | sagaDate: 'SubmittedDateFormat' }}
      </h3>
    </ion-label>
  </ion-item>
  <ion-item *ngIf="parentTask.note && parentTask.note.Text">
    <ion-label class="ion-text-wrap">
      <h3>
        {{ parentTask.note.Text }}
      </h3>
    </ion-label>
  </ion-item>
</ng-container>
<ion-item>
  <ion-label class="ion-text-wrap">
    <h2>
      <strong>{{ task.Name }}</strong>
    </h2>
  </ion-label>
</ion-item>
<ion-item>
  <ion-label class="ion-text-wrap">
    <h3>{{ task.StartDateUTC | sagaDate: 'SubmittedDateFormat' }} - {{ task.EndDateUTC | sagaDate: 'SubmittedDateFormat' }}</h3>
  </ion-label>
</ion-item>
<ion-item>
  <ion-label class="ion-text-wrap">
    <h3 *ngFor="let detail of task.location.FormattedLocation">{{ detail }}</h3>
  </ion-label>
</ion-item>
<ion-item *ngIf="task.note && task.note.Text">
  <ion-label class="ion-text-wrap">
    <h3>
      {{ task.note.Text }}
    </h3>
  </ion-label>
</ion-item>
<ion-accordion-group>
  <ion-accordion *ngIf="units.length" toggleIconSlot="start">
    <ion-item slot="header" color="light">
      <ion-label><strong translate>Units</strong></ion-label>
    </ion-item>
    <div slot="content">
      <app-unit-activity-list [units]="units"> </app-unit-activity-list>
    </div>
  </ion-accordion>
</ion-accordion-group>
