import { Injectable } from '@angular/core';
import { ICustomFilter } from '../../schema/interfaces/ICustomFilter';

@Injectable({
  providedIn: 'root'
})
export class CallCardsCustomFiltersService {
  private _filters: ICustomFilter[] = [];
  get filters(): ICustomFilter[] {
    return this._filters;
  }

  constructor() {}

  addFilter(filter: ICustomFilter) {
    this._filters.push(filter);
  }
}
