import { CoreModule, LOCATION_CONFIG, PROVIDER_ERROR_REPORTER, REQUEST_LOCALIZE, TOKEN_PROVIDER } from '@addins/core/core';
import { MenusModule } from '@addins/core/menus';
import { PanelModule } from '@addins/core/panel';
import { StatusModule } from '@addins/core/status';
import { CommonModule } from '@angular/common';
import { Injector, NgModule } from '@angular/core';
import { SagaDirectivesModule } from '@app/directives';
import { SagaPipeModule } from '@app/pipes/module';
import { SagaServiceModule } from '@app/services';
import { IonicModule } from '@ionic/angular';
import { Addin, AddinModule, IonicCoreModule } from '@techwan/ionic-core';
import { MapModule as MapCoreModule } from '@techwan/ionic-core/map';
import { EDITION_GUARD, MapToolsModule, MAP_PROXY, VALUE_PROVIDER } from '@techwan/map-tools';
import { SharedModule } from '../../../../modules/shared/shared.module';
import { VisualModule } from '../../../../modules/visual/visual.module';
import { BaseLayerSelectComponent } from './components/base-layer-select/base-layer-select.component';
import { CoordinatePickerComponent } from './components/coordinate-picker/coordinate-picker.component';
import { MapFullViewBaseComponent } from './components/full-map-view-base/map-full-view-base.component';
import { FullMapComponent } from './components/full-map/full-map.component';
import { LayersPanelContentComponent } from './components/layers-panel-content/layers-panel-content.component';
import { LeavingDrawingEditionComponent } from './components/leaving-drawing-edition/leaving-drawing-edition.component';
import { MapAddressPopupComponent } from './components/map-address-popup/map-address-popup.component';
import { MapHeaderComponent } from './components/map-header/map-header.component';
import { MapMorePopoverComponent } from './components/map-more-popover/map-more-popover.component';
import { MapPopupComponent } from './components/map-popup/map-popup.component';
import { ObjectChooserComponent } from './components/object-chooser/object-chooser.component';
import { OverlayGroupSelectComponent } from './components/overlay-group-select/overlay-group-select.component';
import { OverlayLayerSelectComponent } from './components/overlay-layer-select/overlay-layer-select.component';
import { PopupBaseComponent } from './components/popup-base/popup-base.component';
import { SearchAddressModalComponent } from './components/search-address-modal/search-address-modal.component';
import { SearchAddressPanelContentComponent } from './components/search-address-panel-content/search-address-panel-content.component';
import { SelectBoxToolComponent } from './components/select-box-tool/select-box-tool.component';
import { ToolGroupComponent } from './components/tool-group/tool-group.component';
import { MODULE_NAME } from './schema';
import { AttributeValueProviderService } from './services/attribute-value-provider/attribute-value-provider.service';
import { CardsLayerService } from './services/cards-layer/cards-layer.service';
import { RequestLocalizeService } from './services/core/request-localize/request-localize.service';
import { DeploymentFeatureFactoryService } from './services/deployment-feature-factory/deployment-feature-factory.service';
import { DrawingLayerService } from './services/drawing-layer/drawing-layer.service';
import { DrawingTocManagerService } from './services/drawing-toc-manager/drawing-toc-manager.service';
import { DrawingTocItemService } from './services/executables/drawing-toc-item/drawing-toc-item.service';
import { LayerControllerService } from './services/executables/layer-controller.service';
import { MapAccessMenuService } from './services/executables/map-access-menu.service';
import { MyDeploymentService } from './services/executables/my-deployment/my-deployment.service';
import { SettingsLoaderService } from './services/executables/settings-loader.service/settings-loader.service';
import { FeatureStyleService } from './services/feature-style/feature-style.service';
import { GeofenceService } from './services/geofence/geofence.service';
import { InMapPanelService } from './services/in-map-panel/in-map-panel.service';
import { LayerControllerService as ControllerService } from './services/layer-controller/layer-controller.service';
import { LocalizePositionService } from './services/localize-position/localize-position.service';
import { LocationConfigService } from './services/location-config/location-config.service';
import { LocationProxyService } from './services/location-proxy/location-proxy.service';
import { MapConfigurationService } from './services/map-configuration/map-configuration.service';
import { MapEditionConfirmationService } from './services/map-edition-confirmation/map-edition-confirmation.service';
import { MapImagesService } from './services/map-images/map-images.service';
import { MapProxyService } from './services/map-proxy/map-proxy.service';
import { MapSettingsService } from './services/map-settings/map-settings.service';
import { MapShowAddressService } from './services/map-show-address/map-show-address.service';
import { MyDeploymentLayerService } from './services/my-deployment-layer/my-deployment-layer.service';
import { MyLocationService } from './services/my-location/my-location.service';
import { NoProviderService } from './services/no-provider/no-provider.service';
import { PickerService } from './services/picker/picker.service';
import { PopupProviderService } from './services/popup-provider/popup-provider.service';
import { PositionLayerService } from './services/position-layer/position-layer.service';
import { ProjectionConverterService } from './services/projection-converter/projection-converter.service';
import { TacticalDrawingsService } from './services/tactical-drawings/tactical-drawings.service';
import { TokenProviderService } from './services/token-provider/token-provider.service';
import { MapFabViewComponent } from './views/map-fab-view/map-fab-view.component';
import { MapFullNoTocViewComponent } from './views/map-full-no-toc-view/map-full-no-toc-view.component';
import { MapFullViewComponent } from './views/map-full-view/map-full-view.component';
import { MapPageViewComponent } from './views/map-page-view/map-page-view.component';

const drawingGuard = { provide: EDITION_GUARD, useExisting: MapEditionConfirmationService };
const mapProxy = { provide: MAP_PROXY, useExisting: MapProxyService };
const valueProvider = { provide: VALUE_PROVIDER, useExisting: AttributeValueProviderService };
const components = [
  FullMapComponent,
  MapPopupComponent,
  MapAddressPopupComponent,
  MapHeaderComponent,
  PopupBaseComponent,
  ToolGroupComponent,
  MapFabViewComponent,
  LayersPanelContentComponent,
  SearchAddressPanelContentComponent,
  SearchAddressModalComponent,
  MapFullViewComponent,
  MapPageViewComponent,
  ObjectChooserComponent,
  SelectBoxToolComponent,
  MapMorePopoverComponent,
  BaseLayerSelectComponent,
  CoordinatePickerComponent,
  MapFullNoTocViewComponent,
  OverlayGroupSelectComponent,
  OverlayLayerSelectComponent,
  LeavingDrawingEditionComponent,
  MapFullViewBaseComponent
];

const declarations = [...components];

const executables = [
  MapAccessMenuService,
  PickerService,
  LayerControllerService,
  DrawingTocItemService,
  MyDeploymentService,
  SettingsLoaderService
];

const providers = [
  mapProxy,
  drawingGuard,
  valueProvider,
  GeofenceService,
  MapProxyService,
  MapImagesService,
  CardsLayerService,
  ControllerService,
  InMapPanelService,
  MyLocationService,
  NoProviderService,
  MapSettingsService,
  DrawingLayerService,
  FeatureStyleService,
  PositionLayerService,
  PopupProviderService,
  TokenProviderService,
  LocationConfigService,
  LayerControllerService,
  MapEditionConfirmationService,
  RequestLocalizeService,
  LocalizePositionService,
  MapShowAddressService,
  LocationProxyService,
  MapConfigurationService,
  TacticalDrawingsService,
  DrawingTocManagerService,
  MyDeploymentLayerService,
  DeploymentFeatureFactoryService,
  ProjectionConverterService,
  AttributeValueProviderService,
  { provide: TOKEN_PROVIDER, useExisting: TokenProviderService },
  { provide: LOCATION_CONFIG, useExisting: LocationConfigService },
  { provide: REQUEST_LOCALIZE, useExisting: RequestLocalizeService },
  { provide: PROVIDER_ERROR_REPORTER, useExisting: NoProviderService },
  ...executables
];

@NgModule({
  declarations,
  providers,
  imports: [
    CommonModule,
    SharedModule,
    IonicModule,
    StatusModule,
    PanelModule,
    MenusModule,
    SagaDirectivesModule,
    SagaServiceModule,
    MapCoreModule,
    SagaPipeModule,
    VisualModule,
    MapToolsModule,
    IonicCoreModule,
    CoreModule
  ],
  exports: [
    CommonModule,
    IonicModule,
    StatusModule,
    PanelModule,
    MenusModule,
    SagaServiceModule,
    MapCoreModule,
    SagaPipeModule,
    VisualModule,
    MapToolsModule,
    ...components
  ]
})
@Addin({
  name: MODULE_NAME
})
export class MapModule extends AddinModule {
  constructor(injector: Injector) {
    super(injector, declarations, executables);
  }
}
