import { IAppSetting } from '@addins/core/core';
import { ListSettingValue } from '@addins/core/settings/schema/list-setting-value';
import { SettingValue } from '@addins/core/settings/schema/setting-value';
import { ListSettingWithNames } from '@addins/core/settings/views/settings-editor/settings-editor.component';
import { TitleCasePipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';

import { AppSettingProviderService, SagaSettingsService } from '@services/settings';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-external-itinerary-settings',
  templateUrl: './external-itinerary-settings.component.html',
  styleUrls: ['./external-itinerary-settings.component.scss']
})
export class ExternalItinerarySettingsComponent implements OnInit, OnDestroy {
  private readonly DEFAULT_EXT_ITN_PROVIDER: string = 'None';

  private extItineraryProviderAppSetting: IAppSetting<string> = null;
  private extItineraryChangedSub: Subscription;

  private _extItineraryProviderListSettings: ListSettingWithNames;
  get extItineraryProviderListSettings(): ListSettingWithNames {
    return this._extItineraryProviderListSettings;
  }

  constructor(private appSettingProvider: AppSettingProviderService, private sagaSettings: SagaSettingsService) {}

  ngOnInit() {
    this._extItineraryProviderListSettings = this.createExtItineraryProviderListSettings();

    this.extItineraryChangedSub = this._extItineraryProviderListSettings.setting.$change.subscribe((settingValue: SettingValue) =>
      this.handleExtItineraryProviderChanged(settingValue.value)
    );
    this.appSettingProvider.getSetting<string>('DefaultExtItineraryProvider', this.DEFAULT_EXT_ITN_PROVIDER, true).subscribe(appSetting => {
      this.extItineraryProviderAppSetting = appSetting;
      const savedProvider = this._extItineraryProviderListSettings.setting.values.filter(
        setting => setting.value === this.extItineraryProviderAppSetting.current
      )[0];
      this._extItineraryProviderListSettings.setting.select(savedProvider);
    });
  }

  private createExtItineraryProviderListSettings(): ListSettingWithNames {
    const titleCasePipe = new TitleCasePipe();
    const settingValues: SettingValue[] = this.getExtItineraryProviderValues().map(
      name => new SettingValue(titleCasePipe.transform(name), name)
    );
    const listSettingValue: ListSettingValue = new ListSettingValue('Mobile.DefaultExtItineraryProvider', settingValues);
    listSettingValue.select(settingValues[0]);

    return {
      setting: listSettingValue,
      displayNames: { [this.DEFAULT_EXT_ITN_PROVIDER]: 'Mobile.NoDefaultExtItineraryProvider' },
      readonly: false
    };
  }

  private getExtItineraryProviderValues(): string[] {
    const configuredProviders = this.sagaSettings.getValue('SagaMobileWebClient.ExternalItineraryProvider').split(',');
    return [this.DEFAULT_EXT_ITN_PROVIDER, ...configuredProviders];
  }

  private handleExtItineraryProviderChanged(providerName: string) {
    if (this.extItineraryProviderAppSetting && this.extItineraryProviderAppSetting.current !== providerName) {
      this.extItineraryProviderAppSetting.setValue(providerName);
    }
  }

  ngOnDestroy() {
    this.extItineraryChangedSub.unsubscribe();
  }
}
