import { IFollowMe } from './interfaces/IFollowMe';
import { ToggleTool } from '@techwan/map-tools';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export class FollowMe implements IFollowMe {
  public get isChecked(): boolean {
    return this._tool.isActive;
  }

  public get $change(): Observable<boolean> {
    return this._tool.$event.pipe(map(t => t.isActive));
  }

  constructor(private readonly _tool: ToggleTool) {}
}
