import { SettingsModule } from '@addins/core/settings';
import { Injector, NgModule } from '@angular/core';
import { SagaDirectivesModule } from '@app/directives';
import { IonicModule } from '@ionic/angular';
import { Addin, AddinModule } from '@techwan/ionic-core';
import { ExternalItinerarySettingsComponent } from './components/external-itinerary-settings/external-itinerary-settings.component';
import { StartItineraryMenuService } from './services/executables/start-itinerary-menu/start-itinerary-menu.service';
import { ExternalProviderFactoryService } from './services/external-provider-factory/external-provider-factory.service';
import { GoogleMapsProviderService } from './services/google-maps-provider/google-maps-provider.service';
import { StartItineraryService } from './services/start-itinerary/start-itinerary.service';
import { SygicProviderService } from './services/sygic-provider/sygic-provider.service';
import { TomTomProviderService } from './services/tom-tom-provider/tom-tom-provider.service';

const executes = [StartItineraryMenuService];

const services = [
  ExternalProviderFactoryService,
  TomTomProviderService,
  SygicProviderService,
  GoogleMapsProviderService,
  StartItineraryService
];

const components = [ExternalItinerarySettingsComponent];

const views = [];

@Addin({
  name: 'Ext-Itinerary'
})
@NgModule({
  declarations: [...components],
  imports: [IonicModule, SagaDirectivesModule, SettingsModule],
  exports: [...components],
  providers: [...services, ...executes]
})
export class ExternalItineraryModule extends AddinModule {
  public constructor(injector: Injector) {
    super(injector, views, executes);
  }
}
