import { Component, OnDestroy, OnInit } from '@angular/core';
import { LoginConfigService } from '@services/login-config/login-config.service';
import { AddinView, AuthenticationService, LoginConfiguration, LoginSetupService, LoginSetupState, SetupError } from '@techwan/ionic-core';
import { LoginTypes } from '@techwan/security';
import { LoginHandlerService } from '../../services/login-handler/login-handler.service';
import { LoginSetupComponent } from '../login-setup/login-setup.component';

export enum View {
  login,
  role,
  profile,
  loading
}

@Component({
  selector: 'app-generic-login',
  templateUrl: 'generic-login.component.html',
  styleUrls: ['generic-login.component.scss']
})
@AddinView({
  name: 'GenericLogin'
})
export class GenericLoginComponent extends LoginSetupComponent implements OnInit, OnDestroy {
  view: View = View.login;

  readonly loginView = View.login;
  readonly roleView = View.role;
  readonly profileView = View.profile;

  get error(): string {
    return `SetupError.${SetupError[this._loginSetup.error]}`;
  }

  get hasError(): boolean {
    return this._loginSetup.error !== SetupError.none;
  }

  get config(): LoginConfiguration {
    return this._authentication.configuration;
  }

  get loginType(): LoginTypes {
    return LoginTypes[this._loginConfig.loginConfig.LoginType];
  }

  constructor(
    protected readonly _authentication: AuthenticationService,
    private readonly _loginHandler: LoginHandlerService,
    private readonly _loginSetup: LoginSetupService,
    private readonly _loginConfig: LoginConfigService
  ) {
    super(_authentication);
  }

  ngOnInit() {
    this._loginHandler.start();
    this._subs.push(this._loginSetup.state.subscribe(state => this.onStateChanged(state)));
    super.ngOnInit();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this._loginHandler.stop();
  }

  protected onShowLogin() {
    super.onShowLogin();
    this.view = View.login;
  }

  protected onStateChanged(state: LoginSetupState) {
    switch (state) {
      case LoginSetupState.roleSelection:
        this.view = View.role;
        break;

      case LoginSetupState.profileSelection:
        this.view = View.profile;
        break;

      case LoginSetupState.erroneous:
        this.view = View.login;
        break;

      default:
        this.view = View.loading;
    }
  }
}
