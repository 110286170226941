import { MapName } from '@addins/core/core';
import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { Coordinate } from '@models/coordinate';
import { CLocation } from '@models/imported/SagaSchema/CLocation';
import { ILocation } from '@models/interfaces/ILocation';
import { TranslateService } from '@ngx-translate/core';
import { MapInstancesService, MapService } from '@services/map';
import { isValidCoordinate } from '@utils/coordinates';
import * as openLayer from 'openlayers';
import { Observable, Subject, Subscription } from 'rxjs';
import { ILocalizeRequest } from '../../schema/interfaces/ILocalizeRequest';
import { ProjectionConverterService } from '../projection-converter/projection-converter.service';

export interface ILocalizeEvent {
  raw: openLayer.Coordinate;
  converted: openLayer.Coordinate;
}

@Injectable()
export class LocalizePositionService {
  /**
   * This event is triggered when a localizing operation is done.
   * e.g: Listen to this event to show or hide a button to localize my position
   */
  private readonly _centering = new Subject<ILocalizeEvent>();
  get $centering(): Observable<ILocalizeEvent> {
    return this._centering.asObservable();
  }

  isCentering: boolean = false;

  constructor(
    private _mapInstancesService: MapInstancesService,
    private _mapService: MapService,
    private _projectionConverter: ProjectionConverterService,
    private _toastCtrl: ToastController,
    private _translateService: TranslateService
  ) {}

  listenForLocation(event: Observable<ILocalizeRequest>): Subscription {
    return event.subscribe(localizeRequest => this.localize(localizeRequest));
  }

  centerOnLocation(mapView: openLayer.View, location: ILocation, zoom: number = -1) {
    const target: Coordinate = {
      x: location.coordinate.x,
      y: location.coordinate.y,
      epsg: this._projectionConverter.getProjection(location.coordinate.epsg)
    };
    this.centerView(mapView, target, zoom, true);
  }

  centerView(mapView: openLayer.View, coordinate: Coordinate, zoom?: number, animate = false): openLayer.Coordinate {
    return this.setCenter(
      mapView,
      [coordinate.x, coordinate.y],
      this._projectionConverter.getProjection(coordinate.epsg),
      zoom || -1,
      animate
    );
  }

  localize(event: ILocalizeRequest, mapName: string = MapName.main): void {
    this.isCentering = true;
    this._mapService.showMap().then(map => this.localizePositions(event, map.getView()));
  }

  private localizePositions(localizeRequest: ILocalizeRequest, mapView: openLayer.View) {
    if (localizeRequest.positions.length === 1) {
      this.localizeOn(mapView, localizeRequest.positions[0], localizeRequest.zoom || -1);
    } else {
      this.localizeExtent(mapView, localizeRequest.positions);
    }
  }

  private localizeOn(view: openLayer.View, location: CLocation, zoom: number) {
    this.setCenter(view, location.getOLCoordinate(), this._projectionConverter.getProjection(location.coordinate.epsg), zoom, true);
  }

  private setCenter(
    mapView: openLayer.View,
    coordinate: openLayer.Coordinate,
    projection: string,
    zoom: number,
    animate: boolean
  ): openLayer.Coordinate {
    let center: ol.Coordinate = null;
    if (isValidCoordinate(coordinate)) {
      center = this._projectionConverter.convertToView(mapView, coordinate, projection);
      if (animate === true) {
        this._mapInstancesService.getMapForView(mapView).moveWithAnimation({
          center,
          resolution: zoom > -1 ? zoom : null,
          duration: 300
        });
      } else {
        mapView.setCenter(center);
        if (zoom > -1) {
          mapView.setResolution(zoom);
        }
      }
      this._centering.next({ raw: coordinate, converted: center });
    } else {
      this.displayLocalizeErrorMessage();
    }

    this.isCentering = false;
    return center;
  }

  private displayLocalizeErrorMessage(errorMsgKey: string = 'SagaMobile.Localize.Error.Message'): Promise<void> {
    return this._toastCtrl
      .create({
        message: this._translateService.instant(errorMsgKey),
        duration: 3000
      })
      .then(ionToast => ionToast.present());
  }

  private localizeExtent(mapView: openLayer.View, locations: CLocation[]): void {
    const convert = locations.map(location =>
      this._projectionConverter.convertToView(
        mapView,
        location.getOLCoordinate(),
        this._projectionConverter.getProjection(location.coordinate.epsg)
      )
    );
    mapView.fit([
      Math.min(convert[0][0], convert[1][0]),
      Math.min(convert[0][1], convert[1][1]),
      Math.max(convert[0][0], convert[1][0]),
      Math.max(convert[0][1], convert[1][1])
    ]);

    this.isCentering = false;
  }
}
