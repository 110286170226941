<ng-container *ngFor="let wrappedCAnimal of cAnimalWrappers">
  <ion-item>
    <ion-icon slot="start" size="large"></ion-icon>
    <ion-label class="ion-text-wrap">
      <div *ngIf="wrappedCAnimal.wrappedParticular.Kind">
        <h2>
          <strong translate>Mobile.CAnimal.Kind</strong>
        </h2>
        <h2>{{ wrappedCAnimal.wrappedParticular.Kind }}</h2>
      </div>
      <div *ngIf="wrappedCAnimal.wrappedParticular.Race">
        <h2>
          <strong translate>Mobile.CAnimal.Race</strong>
        </h2>
        <h2>{{ wrappedCAnimal.wrappedParticular.Race }}</h2>
      </div>
      <div *ngIf="wrappedCAnimal.wrappedParticular.Identification">
        <h2>
          <strong translate>Mobile.CAnimal.Identification</strong>
        </h2>
        <h2>{{ wrappedCAnimal.wrappedParticular.Identification }}</h2>
      </div>
      <div *ngIf="wrappedCAnimal.wrappedParticular.Proprietor">
        <h2>
          <strong translate>Mobile.CAnimal.Proprietor</strong>
        </h2>
        <h2>{{ wrappedCAnimal.wrappedParticular.Proprietor }}</h2>
      </div>
      <div *ngIf="wrappedCAnimal.wrappedParticular.Text">
        <h2>
          <strong translate>Mobile.CAnimal.Text</strong>
        </h2>
        <h2>{{ wrappedCAnimal.wrappedParticular.Text }}</h2>
      </div>
      <div *ngIf="wrappedCAnimal.wrappedParticular.Color">
        <h2>
          <strong translate>Mobile.CAnimal.Color</strong>
        </h2>
        <h2>{{ wrappedCAnimal.wrappedParticular.Color }}</h2>
      </div>
    </ion-label>
  </ion-item>
</ng-container>
