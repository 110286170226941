import { ILocalizeRequest } from '@addins/core/map';
import { CLocation } from '@models/imported/SagaSchema/CLocation';
import { CallcardButtonTool } from './callcard-button-tool';

export class LocalizeButtonTool extends CallcardButtonTool<ILocalizeRequest> {
  constructor(protected target: CLocation[], text: string, icon: string, position: number) {
    super(text, icon, position);
  }

  click() {
    this._onClick.next({ positions: this.target, target: null });
  }
}
