import { IAppSetting } from '@addins/core/core';
import { Component, OnInit } from '@angular/core';
import { AppSettingProviderService } from '@services/settings';
import { AppSettings } from '@services/settings/schema/enums/app-settings';

@Component({
  selector: 'app-generic-settings',
  templateUrl: './generic-settings.component.html',
  styleUrls: ['./generic-settings.component.scss']
})
export class GenericSettingsComponent implements OnInit {
  private _darkMode: IAppSetting<boolean> = null;
  get darkMode(): boolean {
    return this._darkMode && this._darkMode.current;
  }

  private _largeFontEnabled: IAppSetting<boolean> = null;
  get largeFontEnabled(): boolean {
    return this._largeFontEnabled.current;
  }

  private _largeMenuEnabled: IAppSetting<boolean> = null;
  get largeMenuEnabled(): boolean {
    return this._largeMenuEnabled.current;
  }

  constructor(private appSettingProvider: AppSettingProviderService) {}

  ngOnInit() {
    this.appSettingProvider
      .getBooleanSetting(AppSettings.DarkModeEnabled, false, true)
      .subscribe(appSetting => (this._darkMode = appSetting));

    this.appSettingProvider
      .getBooleanSetting(AppSettings.LargeFontEnabled, false, true)
      .subscribe(appSetting => (this._largeFontEnabled = appSetting));

    this.appSettingProvider
      .getBooleanSetting(AppSettings.LargeMenuEnabled, false, true)
      .subscribe(appSetting => (this._largeMenuEnabled = appSetting));
  }

  onDarkModeChange($event) {
    this._darkMode.setValue($event.detail.checked);
  }

  onLargeFontEnabledChange($event) {
    this._largeFontEnabled.setValue($event.detail.checked);
  }

  onLargeMenuEnabledChange($event) {
    this._largeMenuEnabled.setValue($event.detail.checked);
  }
}
