import { CurrentCardService, MyCardService } from '@addins/core/core';
import { IPermanentMenuEvent } from '@addins/core/menus';
import { Injectable } from '@angular/core';
import { Initializer } from '@services/initializer/initializer.service';
import { LayoutService } from '@services/layout/layout.service';
import { SideMenuService } from '@services/menu/side-menu.service';
import { SagaSettingsService } from '@services/settings';
import { AddinExecute, Execute } from '@techwan/ionic-core';
import { filter, first } from 'rxjs/operators';
import { AccessMenuTool } from '../../../schema/access-menu-tool';
import { MenuEntryService } from '../../menu-entry/menu-entry.service';

@AddinExecute({
  name: 'MyCardAccess'
})
@Injectable()
export class MenuAccessService extends Execute {
  private readonly _entry: AccessMenuTool = new AccessMenuTool();
  get entry(): AccessMenuTool {
    return this._entry;
  }

  constructor(
    private sideMenu: SideMenuService,
    private currentCard: CurrentCardService,
    private menuEntry: MenuEntryService,
    private myCard: MyCardService,
    private layout: LayoutService,
    private initializer: Initializer,
    private sagaSettings: SagaSettingsService
  ) {
    super();
  }

  execute(): void {
    this.initializer.onSetupBefore.subscribe(() => this.onCompleted());
  }

  private onCompleted() {
    this.sagaSettings.$ready
      .pipe(
        filter(ready => ready),
        first()
      )
      .subscribe(() => {
        this.setupCallcardMenu();
      });
  }

  private setupCallcardMenu() {
    const canToggleCardMenu: boolean = this.sagaSettings.getValue('SagaMobileWebClient.CanToggleCardMenu');
    this._entry.setToggleable(canToggleCardMenu);

    if (this.layout.layout.home === 'Home/generic') {
      this.sideMenu.addMenuEntry(this._entry);
      this._entry.onClick.subscribe(menuEvent => this.onClicked(menuEvent));
      this.menuEntry.setup(this._entry);
    }
  }

  private onClicked(menuEvent: IPermanentMenuEvent): void {
    if (!menuEvent.item.isActive) {
      this.currentCard.reset();
    } else {
      this.currentCard.select(this.myCard.myCard);
    }
  }
}
