import { PanelToolBase, PanelToolGroupNames } from '@addins/core/panel';
import { ToggleTool } from '@techwan/map-tools';
import { LayersPanelContentComponent } from '../../components/layers-panel-content/layers-panel-content.component';

export class LayerTreePanelTool extends PanelToolBase {
  readonly name: string = 'LayerTreeTool';
  readonly groupName: string = PanelToolGroupNames.MapToolGroup;
  readonly button: ToggleTool = new ToggleTool('layers-tool-button', ['layers']);
  readonly content = LayersPanelContentComponent;
}
