import { IAppSetting } from '@addins/core/core';
import { Directive, ElementRef, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { AppSettingProviderService } from '@services/settings';
import { AppSettings } from '@services/settings/schema/enums/app-settings';
import { Subscription } from 'rxjs';

const LARGE_FONT_CLASS_NAME: string = 'large-font';

@Directive({
  selector: '[largeFont]'
})
export class LargeFontDirective implements OnInit, OnDestroy {
  private largeFontSetting: IAppSetting<boolean> = null;
  private changeSub: Subscription = null;

  constructor(private elementRef: ElementRef, private renderer: Renderer2, private appSettings: AppSettingProviderService) {}

  ngOnInit() {
    this.appSettings.getBooleanSetting(AppSettings.LargeFontEnabled, false, true).subscribe(appSetting => {
      this.largeFontSetting = appSetting;
      this.changeSub = this.largeFontSetting.$change.subscribe(value => this.onLargeFontChanged(value));
    });
  }

  private onLargeFontChanged(isEnabled: boolean) {
    if (isEnabled) {
      this.renderer.addClass(this.elementRef.nativeElement, LARGE_FONT_CLASS_NAME);
    } else {
      this.renderer.removeClass(this.elementRef.nativeElement, LARGE_FONT_CLASS_NAME);
    }
  }

  ngOnDestroy() {
    if (this.changeSub) {
      this.changeSub.unsubscribe();
      this.changeSub = null;
    }
  }
}
