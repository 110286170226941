import { Injectable } from '@angular/core';
import { AddinExecute, Execute } from '@techwan/ionic-core';
import { filter, map } from 'rxjs/operators';
import { CLocation } from '@models/imported/SagaSchema/CLocation';
import { Coordinate } from '@models/coordinate';
import { StartItineraryService } from '@addins/external-itinerary';
import { ILocalizeRequest, LocalizePositionService } from '@addins/core/map';
import { ButtonsProviderService, CardsProviderService, ICallcardButtonEvent } from '@addins/core/singlecallcard';
import { LocalizeTool } from '../../../schema/localize-tool';
import { NavigateToTool } from '../../../schema/navigate-to-tool';
import { DestinationProviderService } from '../../destination-provider/destination-provider.service';
import { DestinationSelectComponent } from '../../../components/destination-select/destination-select.component';

@AddinExecute({
  name: 'NavigateTo'
})
@Injectable()
export class ButtonProviderService extends Execute {
  private _navigateTool: NavigateToTool;
  private _localizeTool: LocalizeTool;

  constructor(
    private readonly cards: CardsProviderService,
    private readonly _destinations: DestinationProviderService,
    private readonly _buttons: ButtonsProviderService,
    private readonly _localize: LocalizePositionService,
    private readonly _navigate: StartItineraryService
  ) {
    super();
  }

  public init() {
    super.init();
    this._navigateTool = new NavigateToTool();
    this._localizeTool = new LocalizeTool();
    this._destinations.setup();
  }

  public execute(): void {
    this._destinations.state.pipe(filter(e => e)).subscribe(() => this.ready());
  }

  private localize(event: ICallcardButtonEvent): ILocalizeRequest {
    const location = new CLocation();
    location.CenterY = this._destinations.value.CoordY;
    location.CenterX = this._destinations.value.CoordX;
    return {
      target: event.target,
      positions: [location],
      zoom: 1
    };
  }

  private ready() {
    this.cards.add(DestinationSelectComponent);
    this._buttons.add(this._navigateTool).subscribe(e => this.navigate(e));
    this._localize.listenForLocation(this._buttons.add(this._localizeTool).pipe(map(e => this.localize(e))));
    this.refresh();
    this._destinations.current.subscribe(() => this.refresh());
  }

  private refresh() {
    this._localizeTool.show = this._destinations.value !== null;
    this._navigateTool.show = this._destinations.value !== null;
  }

  private navigate(e: ICallcardButtonEvent) {
    const destination: Coordinate = {
      epsg: e.target.location.coordinate.epsg,
      x: this._destinations.value.CoordX,
      y: this._destinations.value.CoordY
    };
    this._navigate.navigateTo(destination);
  }
}
