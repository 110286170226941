<ion-item [lines]="lines">
  <ion-label class="ion-text-wrap">
    <ng-container *ngIf="parentTask">
      <h2>
        <strong>{{ parentTask.Name }}</strong>
      </h2>
      <h3>
        {{ parentTask.StartDateUTC | sagaDate: 'SubmittedDateFormat' }} - {{ parentTask.EndDateUTC | sagaDate: 'SubmittedDateFormat' }}
      </h3>
    </ng-container>

    <h2>
      <strong>{{ task.Name }}</strong>
    </h2>
    <h3>{{ task.StartDateUTC | sagaDate: 'SubmittedDateFormat' }} - {{ task.EndDateUTC | sagaDate: 'SubmittedDateFormat' }}</h3>
    <h3>{{ task.location.FormattedLocation }}</h3>
  </ion-label>

  <ion-icon slot="end" name="location" (click)="locateMission($event)" size="large"></ion-icon>
</ion-item>
