import { LocalizeButtonTool } from '@addins/core/singlecallcard';

export class LocalizeTool extends LocalizeButtonTool {
  constructor() {
    super([], 'LocalizeDest', 'locate', 600);
  }

  click(): void {
    this._onClick.next({ target: null, positions: [] });
  }
}
