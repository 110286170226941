import { ViewLoaderService } from '@addins/core/menus';
import { AdditionalViewToolsService, IPanelChanged, PanelEvent, PanelToolGroupNames } from '@addins/core/panel';
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { IonNav } from '@ionic/angular';
import { LayoutService } from '@services/layout/layout.service';
import { ViewSelectorService } from '@services/view-selector/view-selector.service';
import { MapToolsService } from '@techwan/map-tools';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-rows',
  templateUrl: './rows.component.html',
  styleUrls: ['./rows.component.scss']
})
export class RowsComponent implements AfterViewInit, OnInit, OnDestroy {
  @ViewChild('view') view: IonNav;

  topLayoutSplitterNeeded: boolean = false;
  topLayoutMaxHeight: number = 45;
  topLayoutMinHeight: number = 20;

  private _toolsSubscription: Subscription = null;

  get mainLayout(): string[] {
    return this._layoutService.mainLayout();
  }

  constructor(
    private _viewLoaderService: ViewLoaderService,
    private _layoutService: LayoutService,
    private _viewSelectorService: ViewSelectorService,
    private _additionalViewToolsService: AdditionalViewToolsService,
    private _mapToolsService: MapToolsService
  ) {}

  ngOnInit() {
    this._toolsSubscription = this._additionalViewToolsService.$change
      .pipe(filter(panelChanged => panelChanged.tool && panelChanged.tool.groupName === PanelToolGroupNames.MapToolGroup))
      .subscribe(panelChange => {
        this.onMapToolChanged(panelChange);
      });
  }

  private onMapToolChanged(panelChanged: IPanelChanged) {
    const panelTool = panelChanged.tool;
    const toggleTool = panelTool.button;
    if (panelTool.groupName === PanelToolGroupNames.MapToolGroup) {
      if (panelChanged.name === PanelEvent.toolAdded) {
        this._mapToolsService.mapTools.push(toggleTool);
      } else if (panelChanged.name === PanelEvent.toolRemoved) {
        const index = this._mapToolsService.mapTools.indexOf(toggleTool);
        if (index > -1) {
          this._mapToolsService.mapTools.splice(index, 1);
        }
      }
    }
  }

  ngAfterViewInit() {
    this._viewLoaderService.initialize(this.view, this._viewSelectorService.getView(this._layoutService.entryPage()).element);
  }

  setTopLayoutSplitterNeeded(isNeeded: boolean) {
    setTimeout(() => {
      this.topLayoutSplitterNeeded = isNeeded;
    });
  }

  ngOnDestroy() {
    this._viewLoaderService.reset();
    if (this._toolsSubscription) {
      this._toolsSubscription.unsubscribe();
      this._toolsSubscription = null;
    }
  }
}
