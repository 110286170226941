import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { AuthenticationService } from '@techwan/ionic-core';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class LogoutService {
  constructor(
    private readonly alertCtrl: AlertController,
    private readonly auth: AuthenticationService,
    private readonly translate: TranslateService
  ) {}

  logout(): void {
    this.alertCtrl
      .create({
        message: this.translate.instant('Sagamobile.Disconnect.Confirm'),
        buttons: [
          {
            text: this.translate.instant('Sagamobile.Disconnect.Cancel'),
            role: 'cancel'
          },
          {
            text: this.translate.instant('Sagamobile.Disconnect.BtnConfirm'),
            handler: () => this.clearAndReload()
          }
        ]
      })
      .then(modal => modal.present());
  }

  private clearAndReload() {
    this.auth.logout();
    this.clearCookie();
    location.reload();
  }

  private clearCookie() {
    document.cookie.split(';').forEach(c => {
      document.cookie = c.replace(/^ +/, '').replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/');
    });
  }
}
