<ng-container *ngIf="show()">
  <div class="card-data" appTopLayoutSplitterActivator>
    <div class="first-half">
      <div class="details">
        <app-callcard-detail-info [callcard]="myCard"></app-callcard-detail-info>
        <app-callcard-detail-card *ngFor="let card of cards" [component]="card" [callcard]="myCard"></app-callcard-detail-card>
      </div>
    </div>
    <app-callcard-detail-collapsible [callcard]="myCard"></app-callcard-detail-collapsible>
  </div>
  <app-callcard-detail-buttons-h [card]="myCard"></app-callcard-detail-buttons-h>
</ng-container>
