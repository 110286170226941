import { CurrentCardService, MyCardService, SoundService } from '@addins/core/core';
import { MenuState, SideMenuControllerService } from '@addins/core/menus';
import { AccessMenuTool } from '@addins/core/singlecallcard/schema/access-menu-tool';
import { Injectable } from '@angular/core';
import { CallCard } from '@models/imported/SagaSchema/CallCard';
import { CacheService } from '@services/cache/cache.service';
import { CacheEvent } from '@services/cache/cacheEvent';
import { SagaSettingsService } from '@services/settings';
import { MyUnitService } from '@services/unit-activity/my-unit/my-unit.service';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { ProfileParserService } from '../profile-parser/profile-parser.service';

@Injectable()
export class MenuEntryService {
  private _entry: AccessMenuTool;

  private _subs: Subscription[] = [];

  constructor(
    private _profileParser: ProfileParserService,
    private _currentCardService: CurrentCardService,
    private _myCardService: MyCardService,
    private _myUnitService: MyUnitService,
    private _sideMenuControllerService: SideMenuControllerService,
    private _cacheService: CacheService,
    private _soundService: SoundService,
    private _sagaSettings: SagaSettingsService
  ) {}

  setup(entry: AccessMenuTool) {
    this._entry = entry;

    this._myCardService.setup();

    this._myCardService.$ready.pipe(filter(isReady => isReady)).subscribe(() => {
      this.clearSubscriptions();
      this.onCardServiceReady();
    });
  }

  private clearSubscriptions() {
    while (this._subs.length) {
      this._subs.pop().unsubscribe();
    }
  }

  private onCardServiceReady(): void {
    this._subs.push(this._cacheService.listenForChange(CallCard).subscribe(event => this.onCallcardChanged(event)));

    if (!this._profileParser.shouldSelectCurrentCard()) {
      this._entry.type = this._profileParser.getCardView();
      this._sideMenuControllerService.setup();
    }

    this._subs.push(
      this._myUnitService.mine.$changed.subscribe(() => {
        this.onChanged();
      })
    );
    this.onChanged();
  }

  private onCallcardChanged(cacheEvent: CacheEvent) {
    const callcard: CallCard = cacheEvent.object as CallCard;
    if (callcard && callcard.ObjGuid === this._myCardService.myCardId) {
      if (this._sagaSettings.getValue('SagaMobileWebClient.PlaySoundWhenCallCardUpdated') === true) {
        this._soundService.playNotification();
      }
    }
  }

  private onChanged(): void {
    if (this._entry && !this._myUnitService.isEngaged()) {
      this.disable();
    } else if (this._entry) {
      this.enable();
    }
  }

  private disable() {
    if (this._profileParser.shouldResetCurrentCard()) {
      this._currentCardService.select(null);
    }
    this._entry.disable();
  }

  private enable() {
    this._entry.enable();
    if (this._profileParser.shouldSelectCurrentCard()) {
      this._entry.setState(MenuState.active);
      this.select();
    }
  }

  private select(): void {
    if (!this._currentCardService.current) {
      this._currentCardService.select(this._myCardService.myCard);
    } else {
      this._entry.notifyAttention();
    }
  }
}
