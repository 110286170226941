import { UnitActivityModule } from '@addins/core/unit-activity';
import { CommonModule } from '@angular/common';
import { Injector, NgModule } from '@angular/core';
import { SagaDirectivesModule } from '@app/directives';
import { SagaPipeModule } from '@app/pipes/module';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { ModelFactoryService } from '@services/model-factory';
import { Addin, AddinModule } from '@techwan/ionic-core';
import { StampTextEditorComponent } from './components/stamp-text-editor/stamp-text-editor.component';
import { TaskAddAttachmentButton } from './components/task-add-attachment-button/task-add-attachment-button.component';
import { TaskAttachmentsButton } from './components/task-attachments-button/task-attachments-button.component';
import { TaskDetailsItemComponent } from './components/task-details-item/task-details-item.component';
import { TaskEngagementButton } from './components/task-engagement-button/task-engagement-button.component';
import { TaskJournalButton } from './components/task-journal-button/task-journal-button.component';
import { TaskListItemComponent } from './components/task-list-item/task-list-item.component';
import { UnitActivityMDP } from './models/UnitActivityMDP';
import { PlannedMissionsSetupService } from './services/executables/planned-missions-setup/planned-missions-setup.service';
import { UnitActivityMdpModelService } from './services/unit-activity-mdp-model/unit-activity-mdp-model.service';
import { PlannedMissionDetailsViewComponent } from './views/planned-mission-details-view/planned-mission-details-view.component';
import { PlannedMissionsViewComponent } from './views/planned-missions-view/planned-missions-view.component';

export const PLANNED_MISSIONS_ADDIN_NAME = 'PlannedMissions';

const views = [PlannedMissionsViewComponent, PlannedMissionDetailsViewComponent];

const components = [
  ...views,
  TaskEngagementButton,
  TaskListItemComponent,
  TaskAttachmentsButton,
  TaskAddAttachmentButton,
  TaskJournalButton,
  TaskDetailsItemComponent,
  StampTextEditorComponent
];

const executables = [PlannedMissionsSetupService];

const providers = [...executables];

@Addin({
  name: PLANNED_MISSIONS_ADDIN_NAME
})
@NgModule({
  declarations: [...components],
  entryComponents: components,
  providers,
  imports: [CommonModule, IonicModule, SagaDirectivesModule, SagaPipeModule, TranslateModule, UnitActivityModule]
})
export class PlannedMissionsModule extends AddinModule {
  constructor(injector: Injector, private modelFactory: ModelFactoryService) {
    super(injector, views, executables);
  }

  load() {
    super.load();

    this.modelFactory.registerModelWithFactory(UnitActivityMDP, UnitActivityMdpModelService);
  }
}
