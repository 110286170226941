import { MODULE_NAME } from '@addins/core/map/schema';
import { Injectable } from '@angular/core';
import { ProfileReaderService } from '@services/profile-reader/profile-reader.service';
import { ProfileService } from '@services/profile/profile.service';
import { AddinExecute, Execute } from '@techwan/ionic-core';
import { filter, first } from 'rxjs/operators';
import { CardsLayerService } from '../cards-layer/cards-layer.service';
import { DrawingLayerService } from '../drawing-layer/drawing-layer.service';
import { LayerControllerService as Controller } from '../layer-controller/layer-controller.service';
import { MyDeploymentLayerService } from '../my-deployment-layer/my-deployment-layer.service';
import { PositionLayerService } from '../position-layer/position-layer.service';

@AddinExecute({
  name: 'layers-controller',
  description: 'No description'
})
@Injectable()
export class LayerControllerService extends Execute {
  constructor(
    private readonly _controller: Controller,
    private readonly _position: PositionLayerService,
    private readonly _roDrawing: DrawingLayerService,
    private readonly _cards: CardsLayerService,
    private readonly _myDeployment: MyDeploymentLayerService,
    private readonly _profileReaderService: ProfileReaderService,
    private readonly _profileService: ProfileService
  ) {
    super();
  }

  public execute(): void {
    this._profileService.ready
      .pipe(
        filter(ready => ready),
        first()
      )
      .subscribe(() => {
        const disableDeploymentLayer = this._profileReaderService.getComponentConfig(MODULE_NAME, 'disableDeploymentLayer', false);
        if (!disableDeploymentLayer) {
          this._controller.addController(this._myDeployment);
        }
      });
    this._controller.addController(this._position);
    this._controller.addController(this._roDrawing);
    this._controller.addController(this._cards);
  }
}
