import { Injectable } from '@angular/core';
import { DirectoryEntry, FileEntry, LocalFileSystem } from '@ionic-native/file/ngx';
import { Platform } from '@ionic/angular';
import { SagaSettingsService } from '@services/settings';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { FileBridgeService } from '../file-bridge/file-bridge.service';

interface Cordova {
  cordova: { file: any };
}
interface Window extends LocalFileSystem, Cordova {}
declare const window: Window;

const DEFAULT_STORAGE_DIRECTORY: string = 'dataDirectory';
export enum MOVE_FILE_TO {
  PERSISTENT = 'PERSISTENT',
  TEMPORARY = 'TEMPORARY'
}

@Injectable()
export class FileSystemService {
  private readonly _ready = new BehaviorSubject(false);
  get $ready(): Observable<boolean> {
    return this._ready.asObservable();
  }

  private readonly _root: string = null;

  private _fileSystem = null;
  private _resolver: Function;

  private _resolveLocalFileSystem: boolean = true;

  constructor(private _sagaSettings: SagaSettingsService, private _fileBridge: FileBridgeService, platform: Platform) {
    if (platform.is('cordova')) {
      this._root = (window.cordova && window.cordova.file.tempDirectory) || (window.cordova && window.cordova.file.cacheDirectory) || null;

      this._sagaSettings.$ready.pipe(filter(ready => ready)).subscribe(() => this.setup());
    } else {
      this._ready.next(true);
    }
  }

  private setup(): Promise<void> {
    const moveTo = this._sagaSettings.getValue('SagaMobileWebClient.MoveFileTo') || DEFAULT_STORAGE_DIRECTORY;
    if ((moveTo && moveTo === MOVE_FILE_TO.PERSISTENT) || moveTo === MOVE_FILE_TO.TEMPORARY) {
      this._fileSystem = window[moveTo];
      this._resolver = window.requestFileSystem;
      this._resolveLocalFileSystem = false;
    } else if (window.cordova && window.cordova.file[moveTo]) {
      this._fileSystem = window.cordova.file[moveTo];
      this._resolver = window.resolveLocalFileSystemURL;
    }

    return this.cleanCacheDirectory().finally(() => this._ready.next(true));
  }

  getDocumentsDirectory(): string {
    return (window.cordova && window.cordova.file.documentsDirectory) || null;
  }

  getDataDirectory(): string {
    return (window.cordova && window.cordova.file.dataDirectory) || null;
  }

  getRoot(): Promise<DirectoryEntry> {
    if (this._resolver === null) {
      return this._fileBridge.getEntry(this._root).then((rootEntry: DirectoryEntry) => rootEntry);
    }

    return this.getRootDirectory();
  }

  private getRootDirectory(): Promise<DirectoryEntry> {
    return new Promise((resolve, reject) => {
      let args = [this._fileSystem];
      if (!this._resolveLocalFileSystem) {
        args = args.concat([0, fs => resolve(fs.root), reject]);
      } else {
        args = args.concat([resolve, reject]);
      }
      this._resolver.apply(null, args);
    }).then((rootEntry: DirectoryEntry) => rootEntry);
  }

  getFileFromRoot(filename: string, create: boolean = false): Promise<FileEntry> {
    return new Promise((resolve, reject) =>
      this.getRoot().then(root =>
        root.getFile(
          filename,
          {
            create: create,
            exclusive: false
          },
          resolve,
          reject
        )
      )
    );
  }

  getDirectoryFromRoot(directoryName: string, create: boolean = false): Promise<DirectoryEntry> {
    return new Promise((resolve, reject) =>
      this.getRoot().then(root =>
        root.getDirectory(
          directoryName,
          {
            create: create,
            exclusive: false
          },
          resolve,
          reject
        )
      )
    );
  }

  private cleanCacheDirectory(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (this._root === window.cordova.file.cacheDirectory) {
        window.resolveLocalFileSystemURL(this._root, e => {
          const reader = ((e as any) as DirectoryEntry).createReader();
          reader.readEntries(
            entries => {
              const promises: Promise<void>[] = [];
              entries.forEach(entry => {
                if (entry.isDirectory) {
                  promises.push(this.removeRecursively(entry as DirectoryEntry));
                } else if (entry.isFile) {
                  promises.push(this.removeFile(entry as FileEntry));
                }
              });
              Promise.all(promises).then(() => resolve(), reject);
            },
            err => {
              console.error(`Unable to read ${this._root} entries`, err);
              return reject(err);
            }
          );
        });
      }
    });
  }

  private removeRecursively(entry: DirectoryEntry): Promise<void> {
    return new Promise((resolve, reject) => {
      entry.removeRecursively(
        () => {
          console.log(`${entry.fullPath} successfully removed for cleaning.`);
          return resolve();
        },
        err => {
          console.error(`Unable to remove ${entry.fullPath}`, err);
          return reject(err);
        }
      );
    });
  }

  private removeFile(entry: FileEntry): Promise<void> {
    return new Promise((resolve, reject) => {
      entry.remove(
        () => {
          console.log(`${entry.fullPath} successfully removed for cleaning.`);
          return resolve();
        },
        err => {
          console.error(`Unable to remove ${entry.fullPath}`, err);
          return reject(err);
        }
      );
    });
  }
}
