import { LocalizeButton } from '@addins/core/map/services/position-layer/localize-button';
import { Injectable } from '@angular/core';
import { Coordinate } from '@models/coordinate';
import { UnitActivity } from '@models/imported/SagaSchema/UnitActivity';
import { CacheService, CacheState } from '@services/cache/cache.service';
import { CompletionHandlerDelegate, ICompletionDelegate } from '@services/initializer/completion-handler-delegate';
import { Initializer } from '@services/initializer/initializer.service';
import { ButtonItem, MapService } from '@services/map';
import { Security } from '@services/security/security.service';
import { MyUnitService } from '@services/unit-activity/my-unit/my-unit.service';
import { MyEquipmentService } from '@techwan/ionic-core';
import { Image, LocalizableObjectLayer, ObjectFeature, Map as SagaMap } from '@techwan/mapping';
import { Subscription, zip } from 'rxjs';
import { filter, first } from 'rxjs/operators';
import { IFollowMe } from '../../schema/interfaces/IFollowMe';
import { ILayerController } from '../../schema/interfaces/ILayerController';
import { FeatureStyleService } from '../feature-style/feature-style.service';
import { LocalizePositionService } from '../localize-position/localize-position.service';
import { MyLocationService } from '../my-location/my-location.service';
import { ProjectionConverterService } from '../projection-converter/projection-converter.service';

@Injectable()
export class PositionLayerService implements ICompletionDelegate, ILayerController {
  private readonly localizeButton: ButtonItem;
  private readonly _subs: Subscription[] = [];
  private removeButton: () => void = null;
  private pointerDragListener: any = null;
  private _completion: CompletionHandlerDelegate;

  private _myLocationLayer: LocalizableObjectLayer = null;
  private _myLocationFeature: ObjectFeature = null;
  private _map: SagaMap = null;
  private _item: IFollowMe = null;
  private _unitUpdatedSub: Subscription = null;

  /**
   * Historically the application was always traking the device position. The posibility not to track constantly the device's position had
   * been implemented though to support all Techwan's customers.
   */
  private get isFollowingMe(): boolean {
    return this._item !== null ? this._item.isChecked : true;
  }

  constructor(
    private readonly mapService: MapService,
    private readonly security: Security,
    private readonly cache: CacheService,
    private readonly initializer: Initializer,
    private readonly _featureStyle: FeatureStyleService,
    private readonly _myUnit: MyUnitService,
    private readonly _myLocation: MyLocationService,
    private readonly _projection: ProjectionConverterService,
    private readonly _localize: LocalizePositionService,
    private readonly _myEquipmentService: MyEquipmentService
  ) {
    this.localizeButton = new LocalizeButton(this.mapService, this, () => this.setMapToCurrentLocation());

    this._myUnit.$unitChanged.subscribe(unitActivity => this.onUnitChanged(unitActivity));

    this.initializer.onSetupBefore.pipe(first()).subscribe(() => {
      this._myLocation.setup().subscribe();
    });
  }

  private onUnitChanged(unitActivity: UnitActivity) {
    if (unitActivity === null && this._unitUpdatedSub !== null) {
      this._unitUpdatedSub.unsubscribe();
      this._unitUpdatedSub = null;
    } else if (unitActivity !== null && this._unitUpdatedSub === null) {
      this._unitUpdatedSub = unitActivity.$changed.subscribe(() => this.updateUnitFeature());
    }
  }

  private updateUnitFeature() {
    const unit = this._myUnit.mine;
    if (this._myLocationFeature && unit !== null) {
      this._featureStyle.myLocationStyle(this._myLocationFeature, unit.Name, unit);
    }
  }

  followMe(item: IFollowMe) {
    this._item = item;
    this._subs.push(item.$change.subscribe(() => this.refresh()));
  }

  private refresh() {
    if (this._item.isChecked) {
      this.show(this._myLocation.value);
    }
  }

  private show(coordinate: Coordinate) {
    const view = this._map.getView();
    const convertedCoordinate = this._projection.convertToView(
      view,
      [coordinate.x, coordinate.y],
      this._projection.getProjection(coordinate.epsg)
    );
    if (this.isFollowingMe === true) {
      this._localize.centerView(view, coordinate, 1);
    }
    (this._myLocationFeature.getGeometry() as Image).setCoordinates(convertedCoordinate);
    this._myLocationFeature.changed();
  }

  mapDidChange(map: SagaMap) {
    if (map && this._map === null) {
      this._map = map;

      // Initialize my location feature on the map
      this.initMyLocationLayer();

      this._subs.push(
        this.cache.state
          .pipe(
            filter(cacheState => cacheState === CacheState.ready),
            first()
          )
          .subscribe(() => {
            this.setup();
          })
      );
    } else {
      // The map has become null (e.g. it has been removed from the view).
      this.clean();
      this._map = null;
    }
  }

  /**
   * This is called when the map has changed
   */
  private initMyLocationLayer() {
    let unitName: string; // boot cache might not be ready when we first display the map, so we get the unit name from what info we got in the setup phase
    this._myEquipmentService.myUnit.subscribe(myUnitActivity => (unitName = myUnitActivity.Name)).unsubscribe();

    // Create a new layer
    this._myLocationLayer = this._map.createLocalizableObjectLayer({
      cluster: false,
      updateWhileAnimating: false,
      updateWhileInteracting: false,
      zIndex: 3
    });

    this.createMyLocationFeature();

    if (this._myLocationFeature !== null) {
      this._featureStyle.myLocationStyle(this._myLocationFeature, unitName);
    }

    // Listen to the position of the device.
    this._subs.push(
      this._myLocation.$change
        .pipe(filter(coordinate => coordinate !== this._myLocation.empty))
        .subscribe(coordinate => this.show(coordinate))
    );

    this._completion = new CompletionHandlerDelegate(this.initializer, this);
  }

  /**
   * Create the feature representing my position on the map
   */
  private createMyLocationFeature() {
    const coord = this._myLocation.value;
    this._myLocationFeature = this._myLocationLayer.addObject('@position', [coord.x, coord.y], coord.epsg);
    if (this._myLocationFeature !== null) {
      this.security.setData('MapPositionFeature', this._myLocationFeature);
    }
  }

  private setup() {
    // We're going to wait for the unit to be set first,
    // and then we want an actual location to be set as well (not an empty one).
    // Only after both are available we'll apply the style for our unit.
    this._subs.push(
      zip(
        this._myUnit.$unitChanged.pipe(filter(unit => unit !== null)),
        this._myLocation.$change.pipe(filter(coordinate => coordinate !== this._myLocation.empty))
      )
        .pipe(first())
        .subscribe(([unitActivity, coordinate]) => this.updateUnitFeature())
    );

    this.displayLocation();
  }

  /**
   * Display the current location
   */
  private displayLocation(): void {
    this._map.addReadyCallback(() => {
      if (this.pointerDragListener === null) {
        // !!! WARNING !!! Do not remove brackets because returning a false result will stop the event's propagation.
        this.pointerDragListener = this._map.on('pointerdrag', () => {
          this.mapService.moveToPosition = false;
          return true;
        });
      }
    });
  }

  $onComplete(): void {
    this._completion = null;
    const currentLocation = this._myLocation.value;
    if (this._myLocationFeature !== null) {
      (this._myLocationFeature.getGeometry() as any).setCoordinates(
        this._projection.convertToView(this._map.getView(), [currentLocation.x, currentLocation.y], currentLocation.epsg)
      );
      if (this.removeButton === null) {
        this.removeButton = this.mapService.addButtonItems(this.localizeButton);
      }

      if (this.mapService.moveToPosition) {
        this.setMapToCurrentLocation();
      }
    }
  }

  /**
   * Set the map center to the current location
   */
  private setMapToCurrentLocation(): void {
    const location = this._myLocationFeature;
    if (location !== null) {
      const olCoordinate = (location.getGeometry() as any).getCoordinates();
      this._localize.centerView(
        this._map.getView(),
        {
          x: olCoordinate[0],
          y: olCoordinate[1],
          epsg: this._map
            .getView()
            .getProjection()
            .getCode()
        },
        1,
        true
      );
      this.mapService.moveToPosition = true;
    }
  }

  private clean() {
    while (this._subs.length > 0) {
      this._subs.pop().unsubscribe();
    }

    if (this.pointerDragListener !== null) {
      this._map.un('pointerdrag', this.pointerDragListener.listener);
      this.pointerDragListener = null;
    }

    this.clearLayer();

    if (this.removeButton !== null) {
      this.removeButton();
      this.removeButton = null;
    }

    this._myLocationFeature = null;
  }

  private clearLayer() {
    if (this._myLocationLayer !== null) {
      this._myLocationLayer.clearObjects();
      this._myLocationLayer.clear();
      this._myLocationLayer = null;
    }
  }
}
