import { ModalFactoryService } from '@addins/core/core';
import { AttachmentModalComponent } from '@addins/singlecallcard-attachments';
import { Component, Input, OnInit } from '@angular/core';
import { CAttachment } from '@models/imported/SagaSchema/CAttachment';
import { Task } from '@models/imported/SagaSchema/Task';
import { List } from '@models/list';
import { CacheService } from '@services/cache/cache.service';

@Component({
  selector: 'app-task-attachments-button',
  templateUrl: './task-attachments-button.component.html',
  styleUrls: ['./task-attachments-button.component.scss']
})
export class TaskAttachmentsButton implements OnInit {
  @Input()
  task: Task;

  private attachmentsList: List<CAttachment>;
  get attachments(): CAttachment[] {
    return this.attachmentsList.items;
  }

  constructor(private cache: CacheService, private modalFactory: ModalFactoryService) {}

  ngOnInit() {
    this.attachmentsList = this.cache.getListOf(CAttachment);
    this.attachmentsList.addFilter(attachment => attachment.CallCardId == this.task.ObjGuid);
  }

  showAttachments() {
    this.modalFactory.customModal<void>({
      title: 'Mobile.AttachmentList',
      buttons: 'close',
      content: AttachmentModalComponent,
      data: { cardId: this.task.ObjGuid, title: this.task.Name }
    });
  }
}
