import { MyCardService } from '@addins/core/core';
import { Injectable } from '@angular/core';
import { CLocation } from '@models/imported/SagaSchema/CLocation';
import { UnitActivity } from '@models/imported/SagaSchema/UnitActivity';
import { ILocation } from '@models/interfaces/ILocation';
import { MyUnitService } from '@services/unit-activity/my-unit/my-unit.service';
import { MapToolsService, ToggleTool, ToolBase, ToolName } from '@techwan/map-tools';
import { Observable, of, Subscription } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { FollowMe } from '../../schema/follow-me';
import { ILocalizeRequest } from '../../schema/interfaces/ILocalizeRequest';
import { LocalizePositionService } from '../localize-position/localize-position.service';
import { MapShowAddressService } from '../map-show-address/map-show-address.service';
import { MyDeploymentLayerService } from '../my-deployment-layer/my-deployment-layer.service';
import { MyLocationService } from '../my-location/my-location.service';
import { PositionLayerService } from '../position-layer/position-layer.service';

const HIDE_BUTTON_CLASS = 'ion-hide';

@Injectable()
export class InMapPanelService {
  private _routeButton: ToggleTool = null;
  private _fullscreenButton: ToggleTool = null;
  private _toggleDeployButton: ToggleTool = null;
  private _removeLocationButton: ToolBase = null;
  private _localizeLocationButton: ToolBase = null;
  private readonly _cardButtonsSubs: Subscription[] = [];
  private readonly _listeners: Subscription[] = [];
  private _unitSub: Subscription = null;

  get routeButton(): ToggleTool {
    return this._routeButton;
  }

  get fullscreenButton(): ToggleTool {
    return this._fullscreenButton;
  }

  get toggleDeployButton(): ToggleTool {
    return this._toggleDeployButton;
  }

  constructor(
    private _mapToolsService: MapToolsService,
    private _positionLayerService: PositionLayerService,
    private _localizePositionService: LocalizePositionService,
    private _myLocationService: MyLocationService,
    private _myUnitService: MyUnitService,
    private _myCardService: MyCardService,
    private _myDeploymentLayer: MyDeploymentLayerService,
    private _mapShowAddress: MapShowAddressService
  ) {}

  setup() {
    this._mapToolsService.setup();
    this.initButtons();

    this._myUnitService.$unitChanged.subscribe(unitActivity => this.update(unitActivity));

    this._listeners.push(
      this._localizePositionService.listenForLocation(
        (this._mapToolsService.getTool(ToolName.LocalizeMe) as ToolBase).$event.pipe(switchMap(() => this.localizeMe()))
      )
    );
    this._listeners.push(
      this._localizePositionService.listenForLocation(
        (this._mapToolsService.getTool(ToolName.ZoomMe) as ToolBase).$event.pipe(switchMap(() => this.localizeMe(1)))
      )
    );

    this._positionLayerService.followMe(new FollowMe(this._mapToolsService.getTool(ToolName.FollowMe) as ToggleTool));

    this._listeners.push(this._mapShowAddress.$event.subscribe(address => this.onShowAddress(address)));
    this._listeners.push(this._removeLocationButton.$event.subscribe(() => this.onRemoveLocation()));
    this._listeners.push(
      this._localizePositionService.listenForLocation(
        this._localizeLocationButton.$event.pipe(
          switchMap(() => of({ positions: [this._mapShowAddress.currentAddress as CLocation], target: null }))
        )
      )
    );

    this.setupToggleDeployButton();

    this.hideButton(this.routeButton);
  }

  private initButtons() {
    this._routeButton = this._mapToolsService.getTool(ToolName.DrawAndRemoveRoute) as ToggleTool;
    this._fullscreenButton = this._mapToolsService.getTool(ToolName.Fullscreen) as ToggleTool;
    this._toggleDeployButton = this._mapToolsService.getTool(ToolName.ToggleDeployment) as ToggleTool;
    this._removeLocationButton = this._mapToolsService.getTool(ToolName.RemoveLocation) as ToolBase;
    this._localizeLocationButton = this._mapToolsService.getTool(ToolName.LocalizeLocation) as ToolBase;
  }

  private setupToggleDeployButton() {
    this.hideButton(this._toggleDeployButton);
    this._listeners.push(
      this._toggleDeployButton.$event.subscribe(toggleDeployButton =>
        this._myDeploymentLayer.showDeploymentPoint(toggleDeployButton.isActive)
      )
    );
    this._listeners.push(
      this._myDeploymentLayer.$active.subscribe(isActive =>
        isActive ? this.showButton(this._toggleDeployButton) : this.hideButton(this._toggleDeployButton)
      )
    );
    this._listeners.push(this._myDeploymentLayer.$visible.subscribe(isVisible => this._toggleDeployButton.setActive(isVisible)));
  }

  private onShowAddress(address: ILocation) {
    if (address !== null) {
      this.showButton(this._removeLocationButton);
      this.showButton(this._localizeLocationButton);
    } else {
      this.hideButton(this._removeLocationButton);
      this.hideButton(this._localizeLocationButton);
    }
  }

  private onRemoveLocation() {
    this._mapShowAddress.clear();
  }

  setDown() {
    this._mapToolsService.cleanup();

    while (this._listeners.length > 0) {
      this._listeners.pop().unsubscribe();
    }
    // Stop listening to buttons
    while (this._cardButtonsSubs.length > 0) {
      this._cardButtonsSubs.pop().unsubscribe();
    }
  }

  hideButton(button: ToolBase) {
    button.cssClass = HIDE_BUTTON_CLASS;
    button.disable();
  }

  showButton(button: ToolBase, enable: boolean = true) {
    if (enable) {
      button.enable();
    }
    button.cssClass = '';
  }

  private localizeMe(zoom: number = -1): Observable<ILocalizeRequest> {
    return this._myLocationService.getMyLocation().pipe(
      map(l => {
        return { positions: [l], target: null, zoom };
      })
    );
  }

  private localizeMyCard(withMe: boolean = false): Observable<ILocalizeRequest> {
    const location = this._myCardService.myCard.location;
    return withMe === true
      ? this.localizeMe().pipe(tap(v => v.positions.push(location)))
      : of({ positions: [this._myCardService.myCard.location], target: null });
  }

  private update(unitActivity: UnitActivity = null) {
    if (unitActivity === null && this._unitSub !== null) {
      this._unitSub.unsubscribe();
      this._unitSub = null;
    }
    if (unitActivity === null) {
      this.hideButton(this._mapToolsService.getTool(ToolName.LocalizeMyCard) as ToolBase);
      this.hideButton(this._mapToolsService.getTool(ToolName.LocalizeMeAndMyCard) as ToolBase);
    } else if (this._cardButtonsSubs.length === 0) {
      this._unitSub = unitActivity.$changed.subscribe(() => this.initMyCardButtons());
      this.initMyCardButtons();
    }

    // Stop listening to the button events.
    if (unitActivity === null && this._cardButtonsSubs.length > 0) {
      while (this._cardButtonsSubs.length > 0) {
        this._cardButtonsSubs.pop().unsubscribe();
      }
    }
  }

  private initMyCardButtons() {
    let button = this._mapToolsService.getTool(ToolName.LocalizeMyCard) as ToolBase;
    if (this._myUnitService.isEngaged() === true) {
      this.engaged(button);
    } else {
      this.hideButton(button);
      button = this._mapToolsService.getTool(ToolName.LocalizeMeAndMyCard) as ToolBase;
      this.hideButton(button);
      while (this._cardButtonsSubs.length > 0) {
        this._cardButtonsSubs.pop().unsubscribe();
      }
    }
  }

  private engaged(button: ToolBase) {
    const register = this._cardButtonsSubs.length === 0;
    this.showButton(button, true);
    if (register) {
      this._cardButtonsSubs.push(
        this._localizePositionService.listenForLocation(button.$event.pipe(switchMap(() => this.localizeMyCard())))
      );
    }
    button = this._mapToolsService.getTool(ToolName.LocalizeMeAndMyCard) as ToolBase;
    this.showButton(button, true);
    if (register) {
      this._cardButtonsSubs.push(
        this._localizePositionService.listenForLocation(button.$event.pipe(switchMap(() => this.localizeMyCard(true))))
      );
    }
  }
}
