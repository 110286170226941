import { CurrentCardService } from '@addins/core/core';
import { CardPageService } from '@addins/core/singlecallcard/services/card-page/card-page.service';
import { ChangeDetectorRef, Component } from '@angular/core';
import { Camera } from '@ionic-native/camera/ngx';
import { ActionSheetController, NavParams, Platform, PopoverController } from '@ionic/angular';
import { CallcardService } from '@services/callcard/callcard.service';
import { Events } from '@services/events/events.service';
import { NavService } from '@services/navigation/navigation.service';
import { ViewConfigurationService } from '@services/view-configuration/view-configuration.service';
import { AddinView } from '@techwan/ionic-core';
import { CallcardDetailBaseComponent } from '../../components/callcard-detail-base/callcard-detail-base.component';
import { CardStampsPopoverService } from '../../services/card-stamps-popover/card-stamps-popover.service';
import { MediaProviderService } from '../../services/media-provider/media-provider.service';

@Component({
  templateUrl: 'page-detail-view.component.html',
  styleUrls: ['page-detail-view.component.scss']
})
@AddinView({
  name: 'PageView'
})
export class PageDetailViewComponent extends CallcardDetailBaseComponent {
  public get hasMedia(): boolean {
    return this.media.count() > 0;
  }

  constructor(
    private readonly cd: ChangeDetectorRef,
    private readonly media: MediaProviderService,
    nav: NavParams,
    cardService: CallcardService,
    events: Events,
    navService: NavService,
    popoverCtrl: PopoverController,
    actionSheetCtrl: ActionSheetController,
    platform: Platform,
    camera: Camera,
    view: ViewConfigurationService,
    current: CurrentCardService,
    _pages: CardPageService,
    public readonly stamps: CardStampsPopoverService
  ) {
    super(nav, cardService, events, navService, popoverCtrl, actionSheetCtrl, platform, camera, view, current, _pages);
  }

  addPicture() {
    this.media.open(this.currentCallCard);
  }

  refreshStamp() {
    this.currentCallCard.refreshStamps.emit();
  }
}
